import React, { useContext, useState, useMemo } from "react";
import styles from "./cashin.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { CashInMethod, Path, FleetStatus, Page, UserAccess } from "enums";
import locale from "localization";
import {
  History,
  CreditCards,
  OverTheCounter,
  OnlineBanking,
  SuccessPurchase,
  FailurePurchase,
  OverTheCounterComingSoon,
} from "images";
import { Intro, Image, Field, Text } from "components/commons";
import CashinCard from "./cashin-card/cashin-card";
import SelectBankForm from "./cashin-online-banking/select-bank-form";
import SelectPanels from "components/commons/select-panel/select-panels";
import { formatNumber, formatVolume, handleRequest, prettifyCashInMethod } from "utils";
import { useApi, useMount, useModal } from "hooks";
import { getWallet } from "apis/wallet.api";
import { FleetContext, AnalyticsContext, FeatureFlagContext, UserContext } from "contexts";
import { NotifModal } from "components/modals";
import { getCashIn, getFuelBalance } from "apis";
import { getFleetCards, updateFleetCard } from "apis/fleet.api";
import { CircularProgress } from "@material-ui/core";
import { useCallback } from "react";
import CashinCardAddCardSuccessModal from "./cashin-card/cashin-card-add-card-success-modal";
import Big from "big.js";
import { otcs } from "./cashin-online-banking/banks";
import CashInOverTheCounter from "./cashin-over-the-counter/cashin-over-the-counter";
import BpiOtpModal from "./bpi-otp-modal";

const CashInModule = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const { page } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { userAccess } = useContext(UserContext);

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.CashIn;
  });

  const hasModifyAccess = getUserAccess?.fullAccess || getUserAccess?.modifyAccess ? false : true;

  const { fleetId, status } = fleet || {};
  const deactivated = status === FleetStatus.Deactivated;
  const history = useHistory();
  const location = useLocation();
  const { state = {} } = location || {};
  const [cashInMethod, setCashInMethod] = useState(state?.paymentMethod || CashInMethod.Card);
  const notifModal = useModal();
  const bpiOtpModal = useModal();
  const cashinCardAddCardSuccessModal = useModal();
  const {
    request: getWalletBalanceRequest,
    loading: fetchingWalletBalance,
    result: wallet = {},
  } = useApi({
    api: getWallet,
    params: {
      fleetId,
    },
    pageError: true,
  });

  const { request: getFuelBalanceRequest, result: fuelBalance = [] } = useApi({
    api: getFuelBalance,
    params: {
      fleetId,
    },
    pageError: true,
  });

  const {
    request: getFleetCardsRequest,
    loading: fetchingFleetCards,
    result: getFleetCardsResult = { fleetCards: [], count: 0 },
  } = useApi({
    api: getFleetCards,
    params: {
      fleetId,
    },
    pageError: true,
  });

  const { request: getCashInRequest } = useApi({
    api: getCashIn,
  });

  const { request: updateFleetCardRequest } = useApi({
    api: updateFleetCard,
    modalError: false,
    pageError: false,
  });

  const { walletBalance = 0 } = wallet;
  const { fleetCards = [] } = getFleetCardsResult;

  const fetchFleetCards = useCallback(
    (rs) => {
      getFleetCardsRequest({ ...rs });
    },
    [getFleetCardsRequest]
  );

  const initCashInModal = useCallback(async () => {
    if (state?.cashInId) {
      const cashIn = await getCashInRequest({ cashInId: state.cashInId });
      const obj = {
        success: {
          content: locale.yourPaymentHasBeenConfirmed,
          title: locale.successTitle,
          secondary: {
            text: locale.buyFuel,
            onClick: () => {
              history.push(Path.BuyFuel);
            },
          },
          primaryText: locale.newCashIn,
          image: SuccessPurchase,
          actions: [
            {
              text: locale.goToCashInHistory,
              onClick: () => {
                history.push(Path.CashInHistory);
              },
            },
          ],
        },
        failed: {
          content: locale.thereWasAnErrorProcessingPayment,
          title: locale.cashInTransactionFailed,
          secondary: null,
          primaryText: locale.backToCashIn,
          image: FailurePurchase,
        },
        cancel: {
          content: locale.thisTransactionWasCanceled,
          title: locale.cashInTransactionCancel,
          secondary: null,
          primaryText: locale.backToCashIn,
          image: FailurePurchase,
        },
      };
      const modalStatus = obj[cashIn?.status] || obj.failed;
      notifModal.show({
        cashInId: state?.cashInId,
        amount: cashIn?.amount,
        paymentMethod: cashIn?.cashinChannel ? prettifyCashInMethod(cashIn?.cashinChannel) : "",
        ...modalStatus,
      });

      history.replace({ ...history.location, state: {} });
    }

    if (
      state?.cashInStatus &&
      state?.referenceNumber &&
      state?.paymentMethod === CashInMethod.Card
    ) {
      const obj = {
        success: {
          content: locale.youMayNowContinueYourCashInTransaction,
          title: locale.cardSuccessfullyAdded,
          primaryText: locale.continue,
        },
        failure: {
          content: locale.weEncounteredAnErrorWhileSavingYourCardDetails,
          title: locale.addingOfCardFailed,
          secondary: null,
          primaryText: locale.gotIt,
        },
      };

      const modalStatus = obj[state?.cashInStatus] || obj.failure;

      handleRequest(
        async () => {
          await updateFleetCardRequest({
            referenceNumber: state?.referenceNumber,
            fleetId,
          });
          fetchFleetCards();
          cashinCardAddCardSuccessModal.show({
            ...modalStatus,
          });
        },
        null,
        (err) => {
          cashinCardAddCardSuccessModal.show({
            ...modalStatus,
          });
        }
      );
      history.replace({ ...history.location, state: {} });
    }
  }, [
    notifModal,
    state,
    cashinCardAddCardSuccessModal,
    fetchFleetCards,
    fleetId,
    getCashInRequest,
    history,
    updateFleetCardRequest,
  ]);

  useMount(() => {
    if (state?.bank === "BPI") {
      bpiOtpModal.show();
    } else {
      initCashInModal();
    }

    getWalletBalanceRequest();
    getFuelBalanceRequest();
    fetchFleetCards();

    page({
      name: Page.ViewCashIn,
    });
  });

  const channels = useMemo(() => {
    return [
      {
        method: CashInMethod.Card,
        image: CreditCards,
        isEnabled: deactivated ? false : true,
        styles: styles.logo,
        form: !fetchingFleetCards ? (
          <CashinCard
            balance={walletBalance}
            loading={fetchingWalletBalance}
            fleetCards={fleetCards}
            disabled={deactivated || hasModifyAccess}
            fetchFleetCardsRequest={() => {
              fetchFleetCards();
            }}
          />
        ) : (
          <CircularProgress />
        ),
      },
      {
        method: CashInMethod.OverTheCounter,
        image: hasFeature("CASHIN_DRAGONPAY") ? OverTheCounter : OverTheCounterComingSoon,
        isEnabled: hasFeature("CASHIN_DRAGONPAY"),
        styles: styles.image,
        form: hasFeature("CASHIN_DRAGONPAY") ? (
          <SelectBankForm
            balance={walletBalance}
            loading={fetchingWalletBalance}
            disabled={deactivated || hasModifyAccess}
            title={locale.selectAnOverTheCounterBank}
            bankList={otcs}
            paymentFollowUp={"2 days"}
          />
        ) : (
          <CashInOverTheCounter />
        ),
      },
      {
        method: CashInMethod.OnlineBanking,
        image: OnlineBanking,
        isEnabled: deactivated ? false : true,
        styles: styles.logo,
        form: (
          <SelectBankForm
            balance={walletBalance}
            loading={fetchingWalletBalance}
            disabled={deactivated || hasModifyAccess}
          />
        ),
      },
    ];
  }, [
    deactivated,
    fetchingFleetCards,
    walletBalance,
    fetchingWalletBalance,
    fleetCards,
    fetchFleetCards,
    hasFeature,
    hasModifyAccess,
  ]);

  const currentLiterBalance = useMemo(() => {
    let clb = Big(0);
    if (fuelBalance.length > 0) {
      fuelBalance.forEach((fb) => {
        clb = Big(clb).add(Big(fb.fuelBalance));
      });
    }
    return formatVolume(clb.toNumber());
  }, [fuelBalance]);

  const form = useMemo(() => {
    const c = channels.find((c) => c.method === cashInMethod);
    return c?.form || null;
  }, [channels, cashInMethod]);

  return (
    <div>
      <NotifModal
        className={styles.modal}
        {...notifModal}
        title={notifModal.referenceNumber ? locale.cardSuccessfullyAdded : notifModal.title}
        primary={{
          text: notifModal.primaryText,
          onClick: () => {
            notifModal.close();
          },
        }}
      >
        <div className={styles.summary}>
          {notifModal.referenceNumber ? (
            <Text>{locale.youMayNowContinueYourCashInTransaction}</Text>
          ) : (
            [
              {
                title: locale.cashInId,
                value: notifModal.cashInId || 0,
              },
              {
                title: locale.paymentMethod,
                value: notifModal.paymentMethod || prettifyCashInMethod(CashInMethod.Card),
              },
              {
                title: locale.amount,
                value: `₱${formatNumber(notifModal.amount, 2)}` || `₱${formatNumber(0, 2)}`,
              },
            ].map((item, index) => {
              return (
                <Field
                  className={styles.field}
                  childrenClassName={styles.value}
                  label={item.title}
                  key={index}
                  labelPosition="left"
                >
                  <Text strong>{item.value}</Text>
                </Field>
              );
            })
          )}
        </div>
      </NotifModal>
      <CashinCardAddCardSuccessModal {...cashinCardAddCardSuccessModal} />
      <div className={styles.intro}>
        <Intro
          actionOnClick={() => {
            history.push(Path.CashInHistory);
          }}
          actionText={
            getUserAccess?.fullAccess || getUserAccess.viewAccess ? locale.cashInHistory : ""
          }
          actionOutlined
          actionStartIcon={<Image src={History} />}
          title={locale.cashIn}
        />
      </div>
      <div style={{ marginTop: "30px", marginBottom: "20px" }}>
        <div style={{ fontWeight: "bold" }}>
          {Boolean(fleet?.inventoryLimit) && (
            <locale.Populate
              text={locale.fuelInventoryLimit}
              items={[formatVolume(fleet.inventoryLimit)]}
            />
          )}
        </div>
        <div style={{ marginTop: "5px", fontWeight: "bold" }}>
          {currentLiterBalance && (
            <locale.Populate text={locale.currentBalanceLimit} items={[currentLiterBalance]} />
          )}
        </div>
      </div>
      <div className={styles.channel}>
        <SelectPanels
          disabled={hasModifyAccess}
          label={locale.selectCashInChannel}
          active={cashInMethod}
          items={channels.map((c) => ({
            onClick: (item) => {
              if (c.isEnabled) {
                setCashInMethod(item.value);
              }
            },
            title: prettifyCashInMethod(c.method),
            value: c.method,
            content: (
              <div className={styles.panel}>
                <Image src={c.image} className={c.styles} />
              </div>
            ),
            className: styles.cashInPaymentMethodPanel,
            enabled: c.isEnabled,
          }))}
        />
      </div>
      <BpiOtpModal {...bpiOtpModal} notifModal={notifModal} {...state} />
      {form}
    </div>
  );
};

export default CashInModule;
