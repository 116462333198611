import { Role, Path, UserAccess } from "enums";
import locale from "localization";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumberOutlined";

const accessLevel = {
  viewAccess: true,
  modifyAccess: true,
  exportAccess: true,
  voidAccess: true,
};

const UserRoleAccess = [
  {
    label: "Dashboard",
    link: Path.Slash,
    icon: "icon-dashboard",
    exact: true,
    key: UserAccess.Dashboard,
    items: [
      {
        key: UserAccess.Dashboard,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.DataAnalyst,
            ...accessLevel,
            modifyAccess: false,
            exportAccess: false,
            voidAccess: false,
          },
        ],
      },
    ],
    permission: [Role.PortalAdmin, Role.DataAnalyst],
  },
  {
    label: locale.cashFlow,
    icon: "icon-cash-flow",
    link: Path.Cash,
    key: UserAccess.Cash,
    items: [
      {
        label: locale.cashIn,
        child: true,
        link: Path.CashIn,
        key: UserAccess.CashIn,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Purchaser,
            fullAccess: true,
          },
          {
            role: Role.DataAnalyst,
            ...accessLevel,
            modifyAccess: false,
            exportAccess: false,
            voidAccess: false,
          },
        ],
      },
    ],
    permission: [Role.PortalAdmin, Role.Purchaser, Role.DataAnalyst],
  },
  {
    label: locale.fuel,
    icon: "icon-fuel",
    link: Path.Fuel,
    key: UserAccess.Fuel,
    items: [
      {
        label: locale.buyFuelCapitalize,
        child: true,
        link: Path.BuyFuel,
        key: UserAccess.BuyFuel,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Purchaser,
            fullAccess: true,
          },
          {
            role: Role.DataAnalyst,
            ...accessLevel,
            modifyAccess: false,
            exportAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.generalFuelCodes,
        child: true,
        link: Path.FuelGenerateCodes,
        key: UserAccess.GenerateFuelCode,
        permission: [
          {
            role: Role.FleetManager,
            fullAccess: true,
          },
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
        ],
      },
      {
        label: locale.myFuelCodes,
        child: true,
        link: Path.MyFuelCode,
        key: UserAccess.MyFuelCode,
        permission: [
          {
            role: Role.FleetManager,
            fullAccess: true,
          },
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.DataAnalyst,
            ...accessLevel,
            modifyAccess: false,
            exportAccess: false,
            voidAccess: false,
          },
        ],
      },
    ],
    permission: [Role.PortalAdmin, Role.Purchaser, Role.FleetManager, Role.DataAnalyst],
  },
  {
    label: locale.fleetActivity,
    icon: "icon-fleet-activity",
    link: Path.Activity,
    key: UserAccess.Activity,
    items: [
      {
        label: locale.redemptions,
        child: true,
        link: Path.Redemption,
        key: UserAccess.Redemption,
        permission: [
          {
            role: Role.FleetManager,
            fullAccess: true,
          },
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.DataAnalyst,
            ...accessLevel,
          },
        ],
      },
      {
        label: locale.fleetCardRedemptionsHistory,
        child: true,
        link: Path.FleetCardRedemptionHistory,
        key: UserAccess.FleetCardRedemptionHistory,
        permission: [
          {
            role: Role.FleetManager,
            fullAccess: true,
          },
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.DataAnalyst,
            ...accessLevel,
          },
        ],
      },
      {
        label: locale.drivers,
        child: true,
        link: Path.Driver,
        key: UserAccess.Driver,
        permission: [
          {
            role: Role.FleetManager,
            fullAccess: true,
          },
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.DataAnalyst,
            ...accessLevel,
            modifyAccess: false,
            exportAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.vehicles,
        child: true,
        link: Path.Vehicle,
        key: UserAccess.Vehicle,
        permission: [
          {
            role: Role.FleetManager,
            fullAccess: true,
          },
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.DataAnalyst,
            ...accessLevel,
            modifyAccess: false,
            exportAccess: false,
            voidAccess: false,
          },
        ],
      },
      // {
      //   label: locale.groups,
      //   child: true,
      //   link: Path.Groups,
      //   key: UserAccess.Groups,
      //   permission: [
      //     {
      //       role: Role.FleetManager,
      //       fullAccess: true,
      //     },
      //     {
      //       role: Role.PortalAdmin,
      //       fullAccess: true,
      //     },
      //     {
      //       role: Role.DataAnalyst,
      //       ...accessLevel,
      //       modifyAccess: false,
      //       exportAccess: false,
      //       voidAccess: false,
      //     },
      //   ],
      // },
    ],
    permission: [Role.PortalAdmin, Role.FleetManager, Role.DataAnalyst],
  },

  {
    label: locale.station,
    icon: "icon-station",
    link: Path.Station,
    key: UserAccess.Station,
    items: [
      {
        label: locale.stationList,
        child: true,
        link: Path.StationList,
        key: UserAccess.StationList,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.FleetManager,
            fullAccess: true,
          },
          {
            role: Role.DataAnalyst,
            ...accessLevel,
          },
        ],
      },
    ],
    permission: [Role.PortalAdmin, Role.FleetManager, Role.DataAnalyst],
  },
  {
    label: locale.otherServices,
    icon: "icon-gear",
    link: Path.OtherServices,
    key: UserAccess.OtherServices,
    items: [
      {
        label: locale.loans,
        child: true,
        link: Path.Loans,
        key: UserAccess.Loans,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
        ],
      },
    ],
    permission: [Role.PortalAdmin],
  },
  {
    label: locale.promos,
    iconComponent: ConfirmationNumberIcon,
    link: Path.Promo,
    key: UserAccess.Promo,
    permission: [Role.PortalAdmin],
  },
  {
    label: locale.accessAuthorization,
    link: Path.AccessAuthorization,
    key: UserAccess.AccessAuthorization,
    icon: "icon-access",
    items: [
      {
        label: locale.userAccess,
        child: true,
        link: Path.UserAccess,
        key: UserAccess.UserAccess,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
        ],
      },
    ],
    permission: [Role.PortalAdmin],
  },
];

export default UserRoleAccess;
