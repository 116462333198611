import { useState, useCallback, useMemo } from "react";
import useMount from "./useMount";
import moment from "moment";
import { checkIfObjectValuesAreEqual } from "utils/object.utils";

/**
 * A hook for easier handling / processing of Table filter logic
 */
const useFilter = (
  initialState = undefined,
  defaultState = {},
  options = {
    excludeCountProps: [],
  }
) => {
  const [filterState, setFilterState] = useState({ ...initialState });

  const [requestState, setRequestState] = useState(
    convertFilterStateToRequest({ ...initialState })
  );
  const [submittedFilter, setSubmittedFilter] = useState({ ...initialState });

  // Filter Hook usage validation
  useMount(() => {
    validateFilterState(initialState);
  });

  const isFilterDirty = useMemo(() => {
    return !checkIfObjectValuesAreEqual(initialState, filterState);
  }, [filterState, initialState]);

  const filterCount = useMemo(() => {
    let count = 0;
    let dateHasBeenCounted = false;
    Object.keys(initialState).forEach((sb) => {
      const submittedValue =
        submittedFilter?.[sb] !== "all" && submittedFilter?.[sb] ? submittedFilter?.[sb] : null;
      const initialValue = initialState[sb] !== "all" && initialState[sb] ? initialState[sb] : null;

      if (!["fleetId", "page", "perPage", "searchKey", ...options.excludeCountProps].includes(sb)) {
        if (Array.isArray(submittedFilter?.[sb])) {
          if (submittedFilter[sb].length !== initialState[sb].length) {
            count++;
          }
          return;
        } else if (typeof submittedFilter?.[sb] === "object") {
          if (["startDate", "endDate"].includes(sb)) {
            if (submittedFilter[sb] !== initialState[sb] && !dateHasBeenCounted) {
              count++;
              dateHasBeenCounted = true;
            }
          } else if (initialState?.[sb]?.value !== submittedFilter?.[sb]?.value) {
            count++;
          }
        } else if (submittedValue !== initialValue) {
          count++;
        }
      }
    });
    return count;
  }, [submittedFilter, initialState, options.excludeCountProps]);

  // const filterCount = useMemo(() => {
  //   let count = 0;

  //   const excludedProperties = ["fleetId", "page", "perPage", ...options.excludeCountProps];
  //   for (const [fsKey, fsValue] of Object.entries(submittedFilter)) {
  //     if (excludedProperties.includes(fsKey)) {
  //       continue;
  //     } else {
  //       for (const [isKey, isValue] of Object.entries(initialState)) {
  //         if (fsKey === isKey && fsValue !== isValue) {
  //           count++;
  //         }
  //       }
  //     }
  //   }

  //   // start and end date filter should count as 1 only, so subtract 1
  //   if (submittedFilter?.startDate && submittedFilter?.endDate) {
  //     count--;
  //   }

  //   return count;
  // }, [submittedFilter, initialState, options.excludeCountProps]);

  const modifyFilters = useCallback(
    (filters) => {
      const newFilters = {
        ...filterState,
        ...filters,
      };

      if (validateFilters(newFilters)) {
        setFilterState(newFilters);

        const newRequestFilters = convertFilterStateToRequest(newFilters);
        setRequestState(newRequestFilters);
        return { filterState: newFilters, requestState: newRequestFilters };
      }
      return { filterState: newFilters, requestState: null };
    },
    [filterState]
  );

  const modifyFilter = useCallback(
    (name, obj) => {
      return modifyFilters({ [name]: obj.value });
    },
    [modifyFilters]
  );

  const clearFilter = useCallback(
    (exclude = []) => {
      let is = initialState;
      let newRequestFilters = convertFilterStateToRequest(initialState);

      if (Object.keys(defaultState).length) {
        Object.keys(defaultState).forEach((key) => {
          if (is[key]) {
            is[key] = defaultState[key];
          }
        });
      }

      if (is.dateRange) {
        is.dateRange = [moment().startOf("day"), moment().endOf("day")];
      }

      if (Object.keys(submittedFilter).length) {
        Object.keys(submittedFilter).forEach((key) => {
          // exclude submitted state here, retains after clearFilter
          if (exclude.includes(key)) {
            const unchangedFilter = { [key]: submittedFilter[key] };
            newRequestFilters = { ...newRequestFilters, ...unchangedFilter };
            is = { ...is, ...unchangedFilter };
          }
        });
      }

      setRequestState(newRequestFilters);
      setFilterState(is);
      return { filterState: { ...initialState }, requestState: newRequestFilters };
    },
    [initialState, defaultState, submittedFilter]
  );

  const submitFilter = useCallback((rs) => {
    setSubmittedFilter(rs);
    return rs;
  }, []);

  return {
    modifyFilter,
    modifyFilters,
    clearFilter,
    filterState,
    requestState,
    isFilterDirty,
    submitFilter,
    submittedFilter: submittedFilter || {},
    filterCount,
  };
};

// Add additional validations here in the future if any
const validateFilters = (filterState) => {
  try {
    validateFilterState(filterState);
    validatePaginationState(filterState);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const validateFilterState = (filterState) => {
  if (!filterState) {
    throw new Error("Filter state is required when using filter hook");
  }
  if (typeof filterState !== "object") {
    throw new Error("Filter state must be an object");
  }
  if (Object.keys(filterState) <= 0) {
    throw new Error("Filter state must have atleast one property");
  }
};

const validatePaginationState = (state) => {
  if (state.hasOwnProperty("page") || state.hasOwnProperty("perPage")) {
    if (!state?.page || typeof state?.page !== "number" || state?.page <= 0) {
      throw new Error(`Invalid page state with value ${state.page}`);
    }
    if (!state?.perPage || typeof state?.perPage !== "number" || state?.perPage <= 0) {
      throw new Error(`Invalid page size state with value of ${state.perPage}`);
    }
  }

  return true;
};

const convertFilterStateToRequest = (filterState) => {
  let request = {};
  for (const [k, filter] of Object.entries(filterState)) {
    if (filter !== null && filter !== undefined && filter !== "" && filter !== "all") {
      request[k] = filter;
    }
  }
  return request;
};

export default useFilter;
