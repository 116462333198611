import { CircularProgress } from "@material-ui/core";
import React, { useMemo } from "react";
import { Radio, Text, Field } from "components/commons";
import styles from "./input-fuel-amount.module.scss";
import locale from "localization";
import classNames from "classnames";
import { InputLitre, InputAmount } from "components/field";
import { formatAmount, parseNumber, formatVolume } from "utils";

const InputFuelAmount = ({
  className,
  type,
  amount,
  productCode,
  literVolume,
  modifyForm,
  setInsufficientBalance,
  fetchingWalletBalance,
  walletBalance,
  disabled,
}) => {
  const total = useMemo(() => {
    const isInsufficient = (value) => {
      const validate = Number(parseNumber(value)) > walletBalance;

      setInsufficientBalance(validate);
      return validate;
    };

    if (type.value === "peso") {
      const t = Number(parseNumber(amount.value)) / productCode.creditPrice;

      return (
        <>
          {!fetchingWalletBalance && isInsufficient(amount.value) && (
            <locale.Populate
              className={styles.error}
              text={locale.youHaveInsufficientBalance}
              items={[]}
            />
          )}
          <locale.Populate text={locale.totalLitersIsAmount} items={[<b>{formatVolume(t)}</b>]} />
        </>
      );
    }
    const t = Number(parseNumber(literVolume.value)) * productCode.creditPrice;
    return (
      <>
        {!fetchingWalletBalance && isInsufficient(t) && (
          <locale.Populate
            className={styles.error}
            text={locale.youHaveInsufficientBalance}
            items={[]}
          />
        )}
        <locale.Populate text={locale.totalAmountIs} items={[<b>{formatAmount(t)}</b>]} />
      </>
    );
  }, [
    type,
    productCode,
    amount,
    literVolume,
    walletBalance,
    setInsufficientBalance,
    fetchingWalletBalance,
  ]);

  const WalletBalance = () => {
    return fetchingWalletBalance ? (
      <CircularProgress />
    ) : (
      <Text>
        <locale.Populate
          text={locale.totalBalance}
          items={[<strong>{formatAmount(walletBalance)}</strong>]}
        />
      </Text>
    );
  };

  return (
    <div className={classNames(className, styles.container)}>
      <Radio
        className={styles.radioContainer}
        radioClassName={styles.radio}
        options={[
          { text: locale.liters, value: "liters" },
          { text: locale.peso, value: "peso" },
        ]}
        {...type}
        disabled={!productCode.value || disabled}
        onChange={(name, { value }) => {
          modifyForm({
            [name]: {
              value,
            },
            [amount.name]: {
              value: "",
              dirty: false,
              disabled: value === "liters",
            },
            [literVolume.name]: {
              value: "",
              dirty: false,
              disabled: value === "peso",
            },
          });
        }}
      />
      {type.value === "peso" ? (
        <Field {...amount}>
          <InputAmount {...amount} disabled={!productCode.value || disabled} innerLabel />
        </Field>
      ) : (
        <Field {...literVolume}>
          <InputLitre {...literVolume} disabled={!productCode.value || disabled} innerLabel />
        </Field>
      )}
      <Text className={styles.total}>{total}</Text>
      <WalletBalance />
    </div>
  );
};

export default InputFuelAmount;
