import { Path } from "enums";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PaymayaPage from "./paymaya.page";
import OnlineBankPage from "./online-bank.page";
import CashinResultPage from "./cash-in-result.page";
import BPIResultPage from "./bpi.page";

const RedirectPage = () => {
  const onlineBankPath = `${Path.Redirect}${Path.Brankas}`;
  const paymayaPath = `${Path.Redirect}${Path.Paymaya}`;

  return (
    <Switch>
      <Route path={paymayaPath} component={PaymayaPage} />
      <Route path={onlineBankPath} component={OnlineBankPage} />
      <Route path={`${Path.Redirect}/bpi`} component={BPIResultPage} />
      <Route path={`${Path.Redirect}/cashin`} component={CashinResultPage} />
      <Redirect to={Path.Slash} />
    </Switch>
  );
};

export default RedirectPage;
