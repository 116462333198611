import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { getPromos } from "apis/promo.api";
import classNames from "classnames";
import { Button, Image, Text, Title } from "components/commons";
import Card from "components/commons/card/card";
import { FleetContext } from "contexts";
import { Path } from "enums";
import { useApi, useModal, useMount } from "hooks";
import usePagination from "hooks/usePagination";
import { BannerImage, GuyWithMagGlass, IntroPromoImage, PromoTicket } from "images";
import localization from "localization";
import React, { useMemo } from "react";
import { useCallback } from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
// import IntroductoryPromo from "./introductory-promo.module";
import styles from "./promo.module.scss";
import ViewPromoModal from "./view-promo.modal";
const Promo = () => {
  const { fleet, notification } = useContext(FleetContext);
  const { fleetId } = fleet;
  const { promo } = notification;
  const history = useHistory();

  const promoModal = useModal();
  const { page, onLoadMore, canLoadMore, data } = usePagination();

  const { request, loading } = useApi({
    api: getPromos,
    params: {
      fleetId,
      page,
      perPage: 8,
      platformType: "plb",
    },
  });

  const modal = useModal();

  const onRequest = useCallback(async () => {
    const res = await request();
    onLoadMore({ data: res.marketingBanners, total: res.count });
  }, [onLoadMore, request]);

  useMount(async () => {
    onRequest();
    if (promo && promo.refetch) {
      promo.refetch();
    }
  });

  const promoDetails = useMemo(() => {
    const { introductoryPromo = {} } = promo.details || {};
    const { longDescription, shortDescription, title } = introductoryPromo;
    return {
      longDescription,
      subtitle: shortDescription,
      title,
      featuredImage: IntroPromoImage,
      buttonLabel: "Join Now",
      onClick: () => {
        history.push(Path.CashIn);
        promoModal.close();
      },
    };
  }, [promo.details, promoModal, history]);

  const promoData = useMemo(() => {
    const res = promoDetails.title ? [promoDetails, ...data] : data;
    if (page === 2 && promoDetails.title) {
      return res.splice(0, 8);
    }
    return res;
  }, [data, promoDetails, page]);

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${BannerImage})`,
      }}
    >
      <Title className={styles.header}>{localization.promos}</Title>
      <div className={styles.gridContent}>
        {data.length ? (
          <Grid container spacing={4}>
            {promoData.map((promo, key) => {
              return (
                <Grid item xs={3} key={key}>
                  <Card>
                    <div className={styles.image}>
                      <Image
                        className={classNames(styles.img, {
                          [`${styles.defaultImage}`]: !promo.featuredImage,
                        })}
                        src={promo.featuredImage || PromoTicket}
                      />
                    </div>
                    <div className={styles.content}>
                      <div>
                        <Text strong className={styles.title}>
                          {promo.title}
                        </Text>
                        <Text subtitle className={styles.details}>
                          {promo.subtitle}
                        </Text>
                      </div>
                      <div>
                        <div
                          className={styles.viewDetails}
                          onClick={() => {
                            modal.show(promo);
                          }}
                        >
                          <Text colored strong>
                            {localization.viewDetails}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : loading ? (
          <Grid container spacing={4}>
            {[0, 1, 2, 3, 4, 5, 6, 7].map((index, key) => {
              return (
                <Grid item xs={3} key={key}>
                  <Card>
                    <div className={styles.image}>
                      <Skeleton />
                    </div>
                    <div className={styles.content}>
                      <div>
                        <Text strong className={styles.title}>
                          <Skeleton />
                        </Text>
                        <Text subtitle className={styles.details}>
                          <Skeleton />
                        </Text>
                      </div>
                      <div>
                        <div className={styles.viewDetails}>
                          <Text colored strong>
                            <Skeleton />
                          </Text>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <div className={styles.noSearchResultRender}>
            <div>
              <Image src={GuyWithMagGlass} />
              <Title small>{localization.noPromoAvailable}</Title>
              <Text subtitle>{localization.youDoNotHaveAnyPromotionsYet}</Text>
            </div>
          </div>
        )}
      </div>

      {canLoadMore && (
        <div>
          <Button
            primary
            outline
            className={styles.button}
            onClick={() => {
              onRequest();
            }}
            loading={loading}
          >
            {localization.loadMore}
          </Button>
        </div>
      )}
      <ViewPromoModal {...modal} />
      {/* <IntroductoryPromo
        request={requestIntoductoryPromo}
        promoDetails={promoDetails}
        promoModal={promoModal}
      /> */}
    </div>
  );
};

export default Promo;
