import React from "react";

import styles from "./user-access-filter.module.scss";

import { Autocomplete, Field, SearchField, Filter } from "components/commons";
import locale from "localization";
import { prettifyRole } from "utils/pretty.utils";
import { Role } from "enums";

const UserAccessFilter = ({   
  filterState,
  applyFilter,
  applyClearFilter, 
  resetFilter,
  modifyFilter,
  searchKey, 
  modifySearch, 
  applySearch, 
  applyClearSearch }) => {
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Filter submit={applyFilter} close={resetFilter} clear={applyClearFilter}>
          <Field
            className={styles.date}
            label={locale.accessType}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="role"
              value={filterState.role}
              options={[
                {
                  label: prettifyRole(Role.PortalAdmin),
                  value: Role.PortalAdmin,
                },
                {
                  label: prettifyRole(Role.Purchaser),
                  value: Role.Purchaser,
                },
                {
                  label: prettifyRole(Role.FleetManager),
                  value: Role.FleetManager,
                },
                {
                  label: prettifyRole(Role.DataAnalyst),
                  value: Role.DataAnalyst,
                },
              ]}
              onChange={(name, { value }) => modifyFilter({ [name]: value })}
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            searchWithSymbols
            placeholder={[locale.emailAddress].join(", ")}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
      </div>
    </div>
  );
};

export default UserAccessFilter;
