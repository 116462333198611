const CashInChannel = {
  Card: "card",
  ManualPaymaya: "manual-paymaya",
  ManualBankDeposit: "manual-bank-deposit",
  OnlineBanking: "online-banking",
  LocqRebate: "locq-rebate",
  IntroductoryPromo: "introductory-promo",
  WithholdingTaxRefund: "withholding-tax-refund",
  SeaoilReciprocity: "seaoil-reciprocity",
  OTC: "otc",
};

export default CashInChannel;
