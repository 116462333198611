import React, { useContext } from "react";
import styles from "./select-fuel.module.scss";
import FuelCard from "components/fuel-card/fuel-card";
import { useApi, useMount } from "hooks";
import { FleetContext } from "contexts";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import { getPumpPrice } from "apis";

const SelectFuel = ({
  value,
  onChange,
  name,
  vertical,
  withPrice,
  getFuelBalanceRequest,
  fetchingFuelBalance,
  fuelBalance,
  disabled,
}) => {
  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet;

  const {
    request: getPumpPriceRequest,
    loading: fetchingPumpPrice,
    result: pumpPrice = [],
  } = useApi({
    api: getPumpPrice,
    params: {
      fleetId,
    },
    pageError: true,
  });

  useMount(async () => {
    const res = await getPumpPriceRequest();
    if (value) {
      const selected = res.find((item) => item.productCode === value);
      if (selected) {
        onChange(name, {
          ...selected,
          value,
        });
      }
    }
    getFuelBalanceRequest();
  });

  return (
    <div className={classNames(styles.products, { [`${styles.vertical}`]: vertical })}>
      {fetchingPumpPrice || fetchingFuelBalance ? (
        <CircularProgress />
      ) : (
        fuelBalance.map((fb, key) => {
          const currPumpPrice = pumpPrice.find((pp) => {
            return pp.productCode === fb.productCode;
          });
          if (withPrice) {
            return (
              <FuelCard
                disabled={
                  currPumpPrice.pumpPrice <= 0 || currPumpPrice.creditPrice <= 0 || disabled
                }
                withPrice
                type={fb.productCode}
                fuelBalance={fb.fuelBalance}
                price={currPumpPrice.pumpPrice}
                creditPrice={currPumpPrice.creditPrice}
                discount={currPumpPrice.discount}
                key={key}
                active={value === fb.productCode}
                onClick={() => {
                  onChange(name, {
                    ...currPumpPrice,
                    value: fb.productCode,
                  });
                }}
                loadingBalance={fetchingFuelBalance}
              />
            );
          }
          return (
            <FuelCard
              type={fb.productCode}
              fuelBalance={fb.fuelBalance}
              key={key}
              active={value === fb.productCode}
              onClick={() => {
                onChange(name, {
                  ...fb,
                  ...currPumpPrice,
                  value: fb.productCode,
                });
              }}
              loadingBalance={fetchingFuelBalance}
            />
          );
        })
      )}
    </div>
  );
};

export default SelectFuel;
