import { getFleets } from "apis";
import { Loader, Text, Title } from "components/commons";
import Header from "components/commons/header/header";
import { AppContext } from "contexts";
import { Page, Path } from "enums";
import { useApi, useMount } from "hooks";
import { useCallback, useContext, useState } from "react";
import styles from "./select-fleet.module.scss";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useHistory } from "react-router-dom";
import { AnalyticsContext } from "contexts";
import { Role } from "enums";

const SelectFleetModule = () => {
  const [userFleets, setUserFleets] = useState([]);
  const history = useHistory();

  const { setPageError } = useContext(AppContext);
  const { page } = useContext(AnalyticsContext);

  const { request: getUserFleetsRequest, loading: fetchingUserFleets } = useApi({
    api: getFleets,
    pageError: true,
  });

  useMount(() => {
    fetchUserFleets();
    page({
      name: Page.ViewSelectFleet,
    });
  });

  const fetchUserFleets = useCallback(async () => {
    const res = await getUserFleetsRequest();

    if (res.length === 0) {
      setPageError(true);
    } else if (res.length === 1) {
      localStorage.setItem("multipleFleet", false);
      localStorage.setItem("fleetId", res[0].fleetId);
      const role = localStorage.getItem("role")   
      const userLandingPage = [
        { role: Role.PortalAdmin, page: Path.Slash},
        { role: Role.Purchaser, page: Path.BuyFuel},
        { role: Role.FleetManager, page: Path.MyFuelCode},
        { role: Role.DataAnalyst, page: Path.Slash},
      ];
      const userPage  = userLandingPage.filter(item => item.role === role);
      
      history.push(userPage[0].page);
     
    } else {
      localStorage.setItem("multipleFleet", true);
      setUserFleets(res);
    }
  }, [getUserFleetsRequest, setPageError, history]);
 
  const selectUserFleet = useCallback(
    (fleetId) => {
      localStorage.setItem("fleetId", fleetId);
      const role = localStorage.getItem("role")   
      const userLandingPage = [
        { role: Role.PortalAdmin, page: Path.Slash },
        { role: Role.Purchaser, page: Path.BuyFuel },
        { role: Role.FleetManager, page: Path.MyFuelCode },
        { role: Role.DataAnalyst, page: Path.Slash },
      ];
      const userPage = userLandingPage.filter((item) => item.role === role);

      history.push(userPage[0].page);
    },
    [history]
  );

  return (
    <>
      <Loader open={fetchingUserFleets} />
      {userFleets.length > 1 && (
        <>
          <Header />
          <div
            style={{
              marginTop: "60px",
              padding: "27px 36px",
              minWidth: "1170px",
              overflowX: "auto",
              minHeight: "100vh",
              backgroundColor: "#ffffff",
            }}
          >
            <Title className={styles.title}>Select Fleet</Title>
            <div className={styles.container}>
              {userFleets.map((uf, i) => (
                <div key={i} className={styles.fleet} onClick={() => selectUserFleet(uf.fleetId)}>
                  <Text>{uf.fleet?.businessName}</Text>{" "}
                  <KeyboardArrowRightIcon className={styles.icon} />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SelectFleetModule;
