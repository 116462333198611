import {
  Autocomplete,
  DateRange,
  Field,
  SearchField,
  Button,
  Filter,
  GroupCheckbox,
} from "components/commons";
import { DispenseType, Event, Products } from "enums";
import React, { useCallback, useMemo } from "react";
import { prettifyDispenseType, prettifyProduct, prettifyPaymentStatus } from "utils/pretty.utils";
import styles from "./fleet-card-redemptions-filter.module.scss";
import locale from "localization";
import { SelectStation } from "components/field";
import classNames from "classnames";
import PaymentStatus from "enums/payment-status";

const FleetCardRedemptionsFilter = ({
  filterState,
  onDateRangeChange,
  onDispenseChange,
  onStationChange,
  resetFilter,
  onSearchChange,
  submitFilter,
  onSearch,
  clearFilter,
  onDownloadFile,
  filterCount,
  fetchingReport,
  onProductChangeCb,
  onPaymentStatusChangeCb,
}) => {
  const onDateRangeChangeCb = useCallback(
    (name, { value, dirty }) => {
      if (onDateRangeChange) return onDateRangeChange(value, dirty);
    },
    [onDateRangeChange]
  );

  const onStationChangeCb = useCallback(
    (name, { label, value }) => {
      if (onStationChange) return onStationChange({ label, value });
    },
    [onStationChange]
  );

  const onDispenseChangeCb = useCallback(
    (name, { label, value }) => {
      if (onDispenseChange) return onDispenseChange({ label, value });
    },
    [onDispenseChange]
  );

  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  const mapOptions = (values = [], prettifier = () => {}) => {
    return [
      ...values.map((value) => ({
        label: prettifier(value),
        value,
      })),
    ];
  };

  const isFormValid = useMemo(() => {
    if (filterState.productCodes?.length) {
      return true;
    }
    return false;
  }, [filterState.productCodes]);

  return (
    <div className={styles.filter}>
      <Filter
        close={resetFilter}
        clear={clearFilter}
        submit={submitFilter}
        filterCount={filterCount}
        disabled={!isFormValid}
      >
        <Field
          className={styles.date}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          label={locale.redemptionDate}
        >
          <DateRange
            value={{
              startDate: filterState.startDate,
              endDate: filterState.endDate,
            }}
            onChange={onDateRangeChangeCb}
          />
        </Field>
        <Field
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          className={styles.redemptionStation}
          label={locale.status}
        >
          <Autocomplete
            name="paymentStatus"
            value={filterState.paymentStatus}
            options={[
              { label: locale.all, value: "all" },
              {
                label: prettifyPaymentStatus(PaymentStatus.Success),
                value: PaymentStatus.Success,
              },
              {
                label: prettifyPaymentStatus(PaymentStatus.Voided),
                value: PaymentStatus.Voided,
              },
            ]}
            onChange={onPaymentStatusChangeCb}
          />
        </Field>
        <Field
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          label={locale.redemptionStation}
        >
          <SelectStation {...filterState.redemptionStation} onChange={onStationChangeCb} />
        </Field>
        <Field
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          className={styles.redemptionStation}
          label={locale.dispensedTo}
        >
          <Autocomplete
            value={filterState.dispensedTo}
            options={[
              { label: "All", value: "all" },
              ...mapOptions(
                [DispenseType.Vehicle, DispenseType.DrumContainer],
                prettifyDispenseType
              ),
            ]}
            onChange={onDispenseChangeCb}
          />
        </Field>
        <GroupCheckbox
          className={styles.productCode}
          title={locale.availableProducts}
          options={[
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.diesel)}></div>
                  {prettifyProduct(Products.Diesel)}
                </div>
              ),
              value: Products.Diesel,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas91)}></div>
                  {prettifyProduct(Products.Gas91)}
                </div>
              ),
              value: Products.Gas91,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas95)}></div>
                  {prettifyProduct(Products.Gas95)}
                </div>
              ),
              value: Products.Gas95,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas97)}></div>
                  {prettifyProduct(Products.Gas97)}
                </div>
              ),
              value: Products.Gas97,
            },
          ]}
          value={filterState.productCodes}
          onChange={(name, { value }) => {
            onProductChangeCb(value);
          }}
        />
      </Filter>
      <Field className={styles.search}>
        <SearchField
          placeholder={locale.driverIdPlateNumberFleetCardNoOrNumberBusinessName}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
          restrict={false}
        />
      </Field>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={onDownloadFile}
          loading={fetchingReport}
          eventName={Event.DownloadRedemptionReport}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default FleetCardRedemptionsFilter;
