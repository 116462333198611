import { InnerLabelTextfield, TextField } from "components/commons";
import React from "react";
import { formatNumber, parseNumber, checkIfValidByDecimalPlaces } from "utils";

const InputAmount = ({ className, onChange, innerLabel, ...props }) => {
  const componentProps = {
    ...props,
    className,
    onChange: (name, { value }) => {
      if (checkIfValidByDecimalPlaces(value, 2) || !value) {
        onChange(name, { value });
      }
    },
    onBlur: (name, { value }) => {
      if (value) {
        onChange(name, { value: `${formatNumber(value, 2)}${!innerLabel ? " ₱" : ""}` });
      }
    },

    onFocus: () => {
      if (props.value) {
        const num = Number(parseNumber(props.value));
        onChange(props.name, { value: num || "" });
      }
    },
  };
  if (innerLabel) {
    return <InnerLabelTextfield {...componentProps} prefix="₱" />;
  }
  return <TextField {...componentProps} />;
};

export default InputAmount;
