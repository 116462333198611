import * as queryString from "query-string";
import React, { useState, useContext } from "react";
import { useForm, useApi, useMount } from "hooks";
import { initialState } from "./login.state";
import styles from "./login.module.scss";
import { login, getUserV2 } from "apis";
import { ErrorCode, Path, Event, Page } from "enums";
import {
  Checkbox,
  Field,
  TextField,
  PasswordField,
  Button,
  Highlight,
  Text,
  Title,
} from "components/commons";
import locale from "localization";
import { handleRequest } from "utils";
import { isEmailValid } from "utils/text.utils";
import { AnalyticsContext } from "contexts";
import { useHistory } from "react-router-dom";

const LoginForm = (props) => {
  const { identify, page } = useContext(AnalyticsContext);
  const parse = queryString.parse(props.location ? props.location.search : null);
  const [errorCode] = useState(parse.code || null);
  const history = useHistory();

  const [incorrectCreds, setIncorrectCreds] = useState();

  const { request: loginRequest, loading: loggingIn } = useApi({
    api: login,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: getUserRequest } = useApi({
    api: getUserV2,
    pageError: true,
  });

  const { fields, modifyField, submitForm, makeFormInvalid, getFormValues, applyFieldErrors } =
    useForm({
      initialState,
    });

  const onBlurValidateEmail = () => {
    if (fields.email.value && !isEmailValid(fields.email.value)) {
      makeFormInvalid(true);
      return applyFieldErrors({
        email: locale.pleaseEnterAValidEmailAddress,
      });
    }
  };

  const handleLogin = async () => {
    setIncorrectCreds(false);
    const { email, password, remember } = getFormValues();
    if (!email || !password) {
      applyFieldErrors({
        email: !email ? "" : null,
        password: !password ? "" : null,
      });
      return;
    }

    handleRequest(
      async () => {
        if (!isEmailValid(fields.email.value)) {
          applyFieldErrors({
            email: locale.pleaseEnterAValidEmailAddress,
          });
          return;
        }
        const res = await loginRequest({ email, password }, () => handleLogin());
        localStorage.setItem("accessToken", res?.AccessToken);
        localStorage.setItem("refreshToken", res.RefreshToken);
        
        const userDetails = await getUserRequest();
        if (userDetails &&
          userDetails.userAccesses.find(
            (value) =>
            value.portal === "fleet"
          ) !== undefined) {    
          const { role } = userDetails?.userAccesses.find((element) => {
            return element.portal === "fleet";
          });
           
          localStorage.setItem("role", role);
          identify(userDetails.userId, {
            userId: userDetails.userId,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: userDetails.email,
          });

          if (remember) {
            localStorage.setItem("email", email);
          } else {
            localStorage.removeItem("email");
          }

          history.push(Path.SelectUserFleet);
            
        } else {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("role");
          makeFormInvalid();
          setIncorrectCreds(true);
          return applyFieldErrors({
            email: "",
            password: "",
          });
        }
      },
      null,
      (err) => {
        if (err.data?.errorCode === ErrorCode.IncorrectCredentials) {
          makeFormInvalid();
          setIncorrectCreds(true);
          return applyFieldErrors({
            email: "",
            password: "",
          });
        }
        return err.showError();
      }
    );
  };

  useMount(() => {
    page({
      name: Page.ViewLogIn,
    });
  });

  return (
    <div className={styles.login}>
      <Title className={styles.title}>{locale.fleetPortal}</Title>
      {!incorrectCreds && errorCode && errorCode === ErrorCode.InvalidInviteFleetToken && (
        <Highlight warning>
          <Text>{locale.invalidActivationLinkToken}</Text>
        </Highlight>
      )}
      {!incorrectCreds && errorCode && errorCode === ErrorCode.ExpiredInviteFleetToken && (
        <Highlight warning>
          <Text>{locale.expiredActivationLinkToken}</Text>
        </Highlight>
      )}
      {incorrectCreds && (
        <Highlight error>
          <Text>{locale.theEnteredEmailPassIncorrect}</Text>
        </Highlight>
      )}
      <Field {...fields.email}>
        <TextField {...fields.email} onChange={modifyField} onBlur={onBlurValidateEmail} />
      </Field>
      <Field {...fields.password}>
        <PasswordField
          {...fields.password}
          onChange={modifyField}
          onEnter={() => {
            submitForm(handleLogin);
          }}
        />
      </Field>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className={styles.remember}>
          <Checkbox {...fields.remember} onChange={modifyField} />
          {fields.remember.label}
        </div>
        <div className={styles.forgotPassword}>
          <span
            onClick={() => {
              history.push(Path.ForgotPassword);
            }}
          >
            {locale.forgotPassword}
          </span>
        </div>
      </div>
      <Button
        primary
        className={styles.loginButton}
        loading={loggingIn}
        onClick={() => {
          submitForm(handleLogin);
        }}
        eventName={Event.LogIn}
      >
        {locale.logIn}
      </Button>
    </div>
  );
};

export default LoginForm;
