const UserAccess = {
  Dashboard: "DASHBOARD",
  Cash: "CASH",
  CashIn: "CASH_IN",
  Fuel: "FUEL",
  BuyFuel: "BUY_FUEL",
  GenerateFuelCode: "GENERATE_FUEL_CODE",
  MyFuelCode: "MY_FUEL_CODE",
  Activity: "ACTIVITY",
  Redemption: "REDEMPTION",
  FleetCardRedemptionHistory: "FLEET_CARD_REDEMPTION_HISTORY",
  Driver: "DRIVER",
  Vehicle: "VEHICLE",
  Station: "STATION",
  StationList: "STATION_LIST",
  OtherServices: "OTHER_SERVICES",
  Loans: "LOANS",
  Promo: "PROMO",
  AccessAuthorization: "ACCESS_AUTHORIZATION",
  UserAccess: "USER_ACCESS",
  Groups: "GROUPS",
};

export default UserAccess;
