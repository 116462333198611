import React, { useMemo } from "react";
import styles from "./price-movement-chart.module.scss";
import { Line } from "react-chartjs-2";
import { formatCurrency } from "utils";
import locale from "localization";
import moment from "moment";
import { DateTime } from "enums";
import "chartjs-adapter-moment";

const PriceMovementChart = ({ data, toolTip, filterState }) => {
  const { startDate, endDate } = filterState;

  const dateUnit = useMemo(() => {
    const weeksDiff = moment(endDate).diff(moment(startDate), "weeks");

    if (weeksDiff <= 1) {
      return "day";
    }
    if (weeksDiff <= 8) {
      return "week";
    }
    if (weeksDiff <= 52) {
      return "month";
    }
    if (weeksDiff <= 144) {
      return "quarter";
    }
    return "year";
  }, [startDate, endDate]);

  const priceGraph = useMemo(() => {
    const { diesel, gas, gas91, gas95, gas97 } = data;

    let datasets = [];
    if (gas91 && gas95 && gas97) {
      datasets = [
        {
          label: locale.diesel,
          data: diesel,
          borderColor: "#e2b40d",
          backgroundColor: "#fff",
        },
        {
          label: locale.populate(locale.gasName, ["91"]),
          data: gas91,
          borderColor: "#10ac84",
          backgroundColor: "#fff",
        },
        {
          label: locale.populate(locale.gasName, ["95"]),
          data: gas95,
          borderColor: "#ee3b34",
          backgroundColor: "#fff",
        },
        {
          label: locale.populate(locale.gasName, ["97"]),
          data: gas97,
          borderColor: "#2e4491",
          backgroundColor: "#fff",
        },
      ];
    } else {
      datasets = [
        {
          label: locale.diesel,
          data: diesel,
          borderColor: "#e2b40d",
          backgroundColor: "#fff",
        },
        {
          label: locale.gas,
          data: gas,
          borderColor: "#753bbd",
          backgroundColor: "#fff",
        },
      ];
    }

    return { datasets };
  }, [data]);

  const options = useMemo(() => {
    const withResults = priceGraph.datasets.some((d) => {
      return d.data.length > 0;
    });

    let minVal = null;

    const minValsObj = priceGraph.datasets
      .filter((d) => {
        return d.data.length > 0;
      })
      .map((d) => {
        return d.data.reduce((prev, curr) => (prev.y < curr.y ? prev : curr));
      });

    if (minValsObj.length > 0) {
      const minValObj = minValsObj.reduce((prev, curr) => (prev.y < curr.y ? prev : curr));

      if (Math.abs(minValObj.y) < 10) {
        minVal = minValObj.y - 0.5;
      } else {
        minVal = minValObj.y - 5;
      }
    }

    return {
      tension: 0.3,
      scales: {
        yAxis: withResults
          ? {
              ticks: {
                callback: (value) => {
                  return formatCurrency(value);
                },
              },
              grid: { drawBorder: false, borderDash: [5, 7] },
              suggestedMin: minVal,
            }
          : { display: false },
        xAxis: withResults
          ? {
              ticks: {
                callback: (value, index) => {
                  if (dateUnit === "month" || dateUnit === "quarter") {
                    const strSplit = value.split(" ");
                    const month = strSplit[0];
                    const year = strSplit[1];

                    if (index === 0) {
                      return [month, year];
                    }

                    if (month === "Jan") {
                      return [month, year];
                    }
                    return month;
                  }
                  return value;
                },
              },
              grid: { display: false },
              type: "time",
              time: {
                unit: dateUnit,
                displayFormats: {
                  day: DateTime.O,
                  week: DateTime.O,
                  month: DateTime.G,
                  quarter: DateTime.G,
                  year: DateTime.N,
                },
              },
            }
          : { display: false },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: (value) => {
              toolTip(value);
            },
            afterLabel: (value) => {
              return moment(value.label).format(DateTime.L);
            },
          },
          displayColors: false,
        },
      },
    };
  }, [toolTip, dateUnit, priceGraph]);

  return (
    <div className={styles.container}>
      <Line data={priceGraph} options={options} />
    </div>
  );
};

export default PriceMovementChart;
