const Event = {
  AddDriver: "Add Driver",
  EditDriver: "Edit Driver Details",
  AddVehicle: "Add Vehicle",
  EditVehicle: "Edit Vehicle Details",
  CashInOnlineBanking: "Cash In By Online Banking",
  CashInByCard: "Cash In By Card",
  CashInOverTheCounter: "Cash In By Over The Counter",
  BuyFuel: "Buy Fuel",
  BatchFuelCode: "Generate Batch Fuel Code",
  QueueFuelCode: "Queue Fuel Code",
  VehicleFinancingLoan: "Apply Vehicle Financing Loan",
  ActivateAccount: "Activate Account",
  LogIn: "Log In",
  DownloadRedemptionReport: "Download Redemption Report",
  DownloadCashInHistoryReport: "Download Cash In History Report",
  DownloadFuelHistoryReport: "Download Fuel History Report",
  DownloadFuelCodeReport: "Download Fuel Code Report",
  DownloadPriceHistoryReport: "Download Price History Report",
  DeactivateFuelCode: "Deactivate Fuel Code",
  DeactivateDriver: "Deactivate Driver",
  DeactivateVehicle: "Deactivate Vehicle",
  SuccessCashIn: "Success create cashin",
  FailedCashIn: "Failed create cashin",
  CashInResult: "Cashin result page",
  AddGroup: "Add Group",
  EditGroup: "Edit Group",
};

const Page = {
  ViewPriceHistory: "View Price History",
  ViewManageFleetDrivers: "Manage Fleet Drivers",
  ViewRedemptions: "Redemptions",
  ViewManageFleetVehicles: "Manage Fleet Vehicles",
  ViewLogIn: "Log In",
  ViewCashIn: "Cash In",
  ViewCashInHistory: "Cash In History",
  ViewDashboard: "Dashboard",
  ViewBuyFuel: "Buy Fuel",
  ViewGenerateFuelCode: "Generate Fuel Code",
  ViewMyFuelCodes: "My Fuel Codes",
  ViewVehicleFinancing: "Vehicle Financing",
  ViewPLBStations: "PriceLOCQ for Business Stations",
  ViewSelectFleet: "Select Fleet",
  ViewManageGroups: "Manage Groups",
};

export { Event, Page };
