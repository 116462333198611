import { Path } from "enums";
import DriverModule from "modules/activity/driver/driver.module";
import RedemptionsModule from "modules/activity/redemptions/redemptions.module";
import FleetCardRedemptionHistoryModule from "modules/activity/fleet-card-redemptions/fleet-card-redemptions.module";
import GroupsModule from "modules/activity/groups/groups.module";
import VehicleModule from "modules/activity/vehicle/vehicle.module";
import StationListModule from "modules/station/station-list/station-list.module";

import React from "react";
import { Route, Switch, Redirect } from "react-router";

const ActivityPage = () => {
  return (
    <Switch>
      <Route exact path={Path.Redemption} component={RedemptionsModule} />
      <Route
        exact
        path={Path.FleetCardRedemptionHistory}
        component={FleetCardRedemptionHistoryModule}
      />
      <Route exact path={Path.Driver} component={DriverModule} />
      <Route exact path={Path.Vehicle} component={VehicleModule} />
      <Route exact path={Path.Groups} component={GroupsModule} />
      <Route exact path={Path.StationList} component={StationListModule} />
      <Redirect to={Path.Redemption} />
    </Switch>
  );
};

export default ActivityPage;
