import { Intro, DataTable, Product, Text, Pill, PopOver } from "components/commons";
import { FleetContext, AnalyticsContext } from "contexts";
import { DateTime, Products, Page } from "enums";
import { useApi, useFilter, useMount, useModal } from "hooks";
import React, { useCallback, useMemo, useContext } from "react";
import { fleetCardRedemptionsColumns } from "./fleet-card-redemptions-columns";
import CardRedemptionsFilter from "./fleet-card-redemptions-filter";
import { fleetCardRedemptionsFilterState } from "./fleet-card-redemptions-filter.state";
import styles from "./fleet-card-redemptions.module.scss";
import { prettifyDispenseType, prettifyProduct, prettifyPaymentStatus } from "utils/pretty.utils";
import { capitalize } from "utils/text.utils";
import locale from "localization";
import { formatVolume } from "utils";
import moment from "moment";
import { getCardRedemptions, generateReport } from "apis";
import RedemptionStatus from "enums/redemption-status";
import { useHistory } from "react-router-dom";
import { SuccessModal } from "components/modals";

const FleetCardRedemptionsModule = (props) => {
  const successModal = useModal();
  const { page } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { location } = useHistory();

  const {
    modifyFilter,
    modifyFilters,
    filterState,
    requestState,
    clearFilter,
    filterCount,
    submitFilter,
    submittedFilter,
  } = useFilter(fleetCardRedemptionsFilterState(fleet.fleetId, location));

  const {
    request: getRedemptionRequest,
    loading: fetchingRedemption,
    result: getRedemptionResult = { data: [], count: 0 },
  } = useApi({
    api: getCardRedemptions,
    pageError: true,
  });

  const { request: generateReportRequest, loading: fetchingReport } = useApi({
    api: generateReport,
    pageError: true,
  });

  useMount(() => {
    fetchRedemption(requestState);
    page({
      name: Page.ViewRedemptions,
    });
  });

  const fetchRedemption = useCallback(
    (rs) => {
      const s = submitFilter(rs);
      getRedemptionRequest({ ...s, productCodes: s.productCodes.join(",") });
    },
    [getRedemptionRequest, submitFilter]
  );

  const preparedRedemptionData = useMemo(() => {
    const { redemptions } = getRedemptionResult;
    if (redemptions && redemptions.length > 0) {
      const preparedData = redemptions.map((d) => {
        const map = new Map();
        map.set("redemptionId", d.redemptionId);
        map.set(
          "redemptionStation",
          <div>
            <Text>{d.station.name}</Text>
            <Text className={styles.subBusinessId}>{d.station.businessName}</Text>
          </div>
        );
        map.set(
          "driverIdAndName",
          <div>
            <Text>{d.driver?.driverLicenseId || locale.any}</Text>
            <Text className={styles.subBusinessId}>
              {d?.driver?.firstName
                ? `${capitalize(d.driver?.firstName)} ${capitalize(d.driver?.lastName)}`
                : locale.any}
            </Text>
          </div>
        );
        map.set(
          "dispensedToPlateNumber",
          <div>
            <Text>{d.dispensedTo ? prettifyDispenseType(d.dispensedTo) : locale.any}</Text>
            <Text className={styles.subBusinessId}>{d.vehicle?.plateNumber || locale.any}</Text>
          </div>
        );
        map.set(
          "redemptionDate",
          <div>
            <Text>{moment(d.redeemedAt).format(DateTime.A)}</Text>
            <Text>{moment(d.redeemedAt).format(DateTime.B)}</Text>
          </div>
        );
        map.set(
          "fuelCodeRedemptionDate",
          <div>
            <Text>{d.cardNumber}</Text>

            <Text className={styles.subBusinessId}> {moment(d.redeemedAt).format(DateTime.I)}</Text>
          </div>
        );
        map.set(
          "productVolume",
          <div>
            <Product
              grass={d.productCode === Products.Gas91}
              salsa={d.productCode === Products.Gas95}
              deepBlue={d.productCode === Products.Gas97}
              cheddar={d.productCode === Products.Diesel}
            >
              {prettifyProduct(d.productCode)}
            </Product>
            <Text className={styles.subBusinessId}> {formatVolume(d.literVolume)}</Text>
          </div>
        );
        map.set("pumpPrice", <div className="nowrap">{`${formatVolume(d.pumpPrice)}`}</div>);
        map.set("pointsEarned", <div className="nowrap">{d.pointsEarned || "0"}</div>);
        map.set("orNumber", d.orNumber);
        map.set(
          "status",
          <Pill
            grass={d.status === RedemptionStatus.Success}
            cement={d.status === RedemptionStatus.Voided}
          >
            {prettifyPaymentStatus(d.status)}
          </Pill>
        );

        map.set(
          "remarks",
          d.remarks ? (
            <PopOver content={<div className={styles.remarks}>{d.remarks}</div>}>
              <div className="link">View</div>
            </PopOver>
          ) : (
            ""
          )
        );

        return map;
      });
      return preparedData;
    }
    return [];
  }, [getRedemptionResult]);

  const onDateRangeCb = useCallback(
    (value) => {
      const { startDate, endDate } = value;
      modifyFilters({ startDate, endDate, page: 1 });
    },
    [modifyFilters]
  );

  const onProductChangeCb = useCallback(
    (productCodes) => {
      modifyFilters({ productCodes, page: 1 });
    },
    [modifyFilters]
  );

  const onStationChangeCb = useCallback(
    (redemptionStation) => {
      modifyFilters({ redemptionStation, redemptionStationId: redemptionStation.value, page: 1 });
    },
    [modifyFilters]
  );

  const onDispenseChangeCb = useCallback(
    (value) => {
      modifyFilters({ dispensedTo: value.value, page: 1 });
    },
    [modifyFilters]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1, perPage: 10 });
      fetchRedemption(requestState);
    },
    [modifyFilters, fetchRedemption]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchRedemption(requestState);
    },
    [modifyFilters, fetchRedemption]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchRedemption(requestState);
    },
    [modifyFilters, fetchRedemption]
  );
  const onPaymentStatusChangeCb = useCallback(
    (name, { value }) => {
      modifyFilters({ redemptionStatus: value, page: 1 });
    },
    [modifyFilters]
  );

  const onDownloadFileCb = async () => {
    const { requestState } = modifyFilters({ reportType: "fuel-card" });
    if (requestState.redemptionStation) {
      requestState.redemptionStationId = requestState.redemptionStation.value;
      delete requestState.redemptionStation;
    }

    delete requestState.perPage;
    delete requestState.page;
    const generateResult = await generateReportRequest(requestState);
    if (generateResult?.downloadUrl) {
      window.open(generateResult?.downloadUrl, "_blank");
    } else {
      successModal.show({
        title: locale.successTitle,
        content: locale.reportGenerated,
      });
    }
  };

  return (
    <div>
      <SuccessModal {...successModal} />
      <div>
        <Intro
          title={locale.fleetCardRedemptionsHistory}
          subtitle={locale.viewTrackRedemptionsFleetCard}
        />
      </div>
      <div className={styles.filters}>
        <CardRedemptionsFilter
          onPaymentStatusChangeCb={onPaymentStatusChangeCb}
          onProductChangeCb={onProductChangeCb}
          filterState={filterState}
          onDateRangeChange={onDateRangeCb}
          onStationChange={onStationChangeCb}
          onSearchChange={modifyFilter}
          onSearch={onSearchCb}
          onDispenseChange={onDispenseChangeCb}
          onDownloadFile={onDownloadFileCb}
          fetchingReport={fetchingReport}
          resetFilter={() => {
            modifyFilters({ submittedFilter });
          }}
          filterCount={filterCount}
          submitFilter={() => {
            const { requestState } = modifyFilters({ page: 1 });
            fetchRedemption(requestState);
          }}
          clearFilter={() => {
            const { requestState } = clearFilter();
            fetchRedemption(requestState);
          }}
        />
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingRedemption}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={fleetCardRedemptionsColumns}
          data={preparedRedemptionData}
          dataCount={getRedemptionResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
          onPaymentStatusChangeCb={onPaymentStatusChangeCb}
        />
      </div>
    </div>
  );
};

export default FleetCardRedemptionsModule;
