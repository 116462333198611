import { AppContext, FleetContext } from "contexts";
import { useContext, useState } from "react";
import { ErrorCode, Path } from "enums";
import { redirectTo } from "utils";

const useApi = ({ api, params = {}, handleOwnError = false, modalError = true, pageError }) => {
  const { errorModal, setPageError, setErrorStatus, deactivatedModal } = useContext(AppContext);
  const { fetchFleet } = useContext(FleetContext) || {};
  const [loading, setLoading] = useState(undefined);
  const [error, setError] = useState(false);
  const [result, setResult] = useState(undefined);

  const showError = (err, retry) => {
    if (pageError) {
      setPageError(true);
      setErrorStatus(err.status);
    } else {
      if (modalError) {
        errorModal.show({
          onClick: async () => {
            errorModal.close();
            await retry();
          },
        });
      }
    }
    throw err;
  };

  const request = async (p, retry = () => {}) => {
    setLoading(true);
    setError(false);
    const obj = { ...params, ...p };
    try {
      const res = await api(obj);
      setResult(res);
      setLoading(false);

      return res;
    } catch (err) {
      console.error("Error", err);

      err.showError = () => showError(err, retry);

      setError(true);
      setErrorStatus(err.status);
      setLoading(false);

      if (err.data?.errorCode === ErrorCode.InactiveFleetStatus) {
        deactivatedModal.show({
          onClick: async () => {
            deactivatedModal.close();
            await fetchFleet();
          },
        });
        // throw errorCode to prevent showError function call
        throw err.data?.errorCode;
      }

      if (err.status === 401 || err.status === 403) {
        // token is invalidated
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("fleetId");

        redirectTo(Path.Auth);
        return;
      }

      if (handleOwnError) {
        if (typeof handleOwnError === "boolean") {
          throw err;
        } else if (typeof handleOwnError === "object") {
          if (handleOwnError.hasOwnProperty(err.error)) {
            throw err;
          } else {
            return showError(err);
          }
        }
      } else {
        return showError(err);
      }
    }
  };

  return { request, loading, result, error, showError };
};

export default useApi;
