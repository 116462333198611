import React, { useContext, useMemo } from "react";
import styles from "./fuel-code-form.module.scss";
import {
  ActionButton,
  Checkbox,
  DateRange,
  Dropdown,
  Field,
  Title,
  PopOverList,
  Text,
  TextField,
} from "components/commons";
import locale from "localization";
import { initialState as formState } from "./generate-fuel-codes.state";
import { InputLitre, SelectDriver, SelectFuel, SelectVehicle } from "components/field";
import { useApi, useForm, useMount } from "hooks";
import { getFuelBalance } from "apis";
import { FleetContext } from "contexts";
import { formatAmount, parseLiter, parseNumber } from "utils";
import moment from "moment";
import { FuelCodeType } from "enums";
import classNames from "classnames";

const FuelCodeForm = ({ title, initialState, loading, close, submit, actionText }) => {
  const { fleet } = useContext(FleetContext);
  const { fleetId, fleetStations = [] } = fleet;
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const {
    fields = {},
    modifyField,
    modifyForm,
    validateField,
    submitForm,
    isFormSubmittable,
    getFormValues,
  } = useForm({
    initialState: form,
  });

  const {
    request: getFuelBalanceRequest,
    loading: fetchingFuelBalance,
    result: fuelBalance = [],
  } = useApi({
    api: getFuelBalance,
    params: {
      fleetId,
    },
    pageError: true,
  });

  const {
    productCode = {},
    literVolume = {},
    duration = {},
    period = {},
    vehicleIds = {},
    driverIds = {},
    fuelCodeType = {},
    limitOneTime = {},
    mobileNumber = {},
    sendAsSms = {},
    autoRenew = {},
    saveTemplate = {},
  } = fields;

  const pesoLiterValue = useMemo(() => {
    const { creditPrice = 0 } = productCode || {};
    const { value: literValue = 0 } = literVolume || {};
    return parseNumber(creditPrice) * parseNumber(literValue);
  }, [productCode, literVolume]);

  const hasMobileNumberInput = useMemo(() => {
    return [FuelCodeType.VehicleSpecific, FuelCodeType.AnyDriverAnyVehicle].includes(
      fuelCodeType.value
    );
  }, [fuelCodeType]);

  useMount(() => {
    getFuelBalanceRequest();
  }, []);
  const noProductCode = !productCode.value || !productCode.creditPrice;
  return (
    <div>
      <div className={styles.headerContainer}>
        {title && (
          <Title small className={styles.title}>
            {title}
          </Title>
        )}

        {fleetStations.length > 0 && (
          <div className={styles.fleetStations}>
            <locale.Populate
              text={locale.addFuelCodeSubTitle}
              items={[
                <PopOverList
                  className={styles.popOver}
                  listTitle={locale.redemptionStations}
                  list={fleetStations.map((station) => {
                    return station.name;
                  })}
                >
                  <span className={styles.linkStationList}>{locale.stations}</span>
                </PopOverList>,
              ]}
            />
          </div>
        )}
      </div>

      <div>
        <Text label>{locale.selectProductType}</Text>
        <SelectFuel
          {...productCode}
          onChange={modifyField}
          getFuelBalanceRequest={getFuelBalanceRequest}
          fetchingFuelBalance={fetchingFuelBalance}
          fuelBalance={fuelBalance}
        />
      </div>

      <div className={styles.form}>
        <Field label={locale.volumeRedeemLimit} {...literVolume}>
          {literVolume.infoMessage ? (
            <div
              className="italic gray"
              style={{
                paddingBottom: "10px",
              }}
            >
              {literVolume.infoMessage}
            </div>
          ) : null}
          <InputLitre {...literVolume} onChange={modifyField} disabled={noProductCode} />
          <Text label className={styles.estimatedPesoValue}>
            <locale.Populate
              text={locale.approxPesoEquivalent}
              items={[
                <span className={styles.estimatedPesoValueAmount}>
                  {formatAmount(pesoLiterValue)}
                </span>,
              ]}
            />
          </Text>
        </Field>
        <div className={styles.subform}>
          <div className={styles.dateContainer}>
            {!period.hidden && (
              <div className={styles.dateRange}>
                <Field label={locale.eligiblePeriod}>
                  <DateRange
                    {...period}
                    disableEnd
                    onChange={(name, value) => {
                      const { startDate } = { ...value.value };
                      const startDateValue = startDate;
                      const endDate = moment(startDateValue).add(
                        parseNumber(duration.value) - 1,
                        "days"
                      );

                      modifyForm({
                        [name]: {
                          value: {
                            ...value.value,
                            endDate: new Date(endDate),
                          },
                        },
                      });
                    }}
                    disabled={noProductCode || period.disabled}
                  />
                </Field>
              </div>
            )}

            <div className={styles.duration}>
              <Field {...duration} label={locale.durationInDays}>
                <TextField
                  {...duration}
                  onChange={(name, { value }) => {
                    const startDateValue = period.disabled ? null : period.value.startDate;
                    const endDate = period.disabled
                      ? null
                      : moment(startDateValue).add(parseNumber(value - 1 || 0), "days");

                    modifyForm({
                      [name]: { value },
                      [period.name]: {
                        value: {
                          startDate: period.disabled ? null : period.value.startDate,
                          endDate: period.disabled ? null : new Date(endDate),
                        },
                      },
                    });
                  }}
                  onBlur={validateField}
                  disabled={!moment(period.value?.startDate, true).isValid() && !period.disabled}
                />
              </Field>
            </div>
          </div>

          <Field label={locale.fuelCodeType}>
            <Dropdown
              {...fuelCodeType}
              onChange={(value) => {
                const obj = {
                  [FuelCodeType.DriverVehicleSpecific]: {
                    [vehicleIds.name]: {
                      value: null,
                      label: "",
                      required: true,
                    },
                    [driverIds.name]: {
                      value: driverIds.value,
                      required: true,
                    },
                    [mobileNumber.name]: {
                      value: "",
                      required: false,
                    },
                  },
                  [FuelCodeType.DriverSpecific]: {
                    [vehicleIds.name]: {
                      value: null,
                      label: "",
                      required: false,
                    },
                    [driverIds.name]: {
                      value: driverIds.value,
                      required: true,
                    },
                    [mobileNumber.name]: {
                      value: "",
                      required: false,
                    },
                  },
                  [FuelCodeType.VehicleSpecific]: {
                    [driverIds.name]: {
                      value: null,
                      label: "",
                      required: false,
                      driverMobileNumber: null,
                    },
                    [vehicleIds.name]: {
                      value: vehicleIds.value,
                      required: true,
                    },
                    [mobileNumber.name]: {
                      value: !sendAsSms.value ? "" : mobileNumber.value,
                      required: sendAsSms.value,
                    },
                  },
                  [FuelCodeType.AnyDriverAnyVehicle]: {
                    [driverIds.name]: {
                      value: null,
                      label: "",
                      required: false,
                      driverMobileNumber: null,
                    },
                    [vehicleIds.name]: {
                      value: null,
                      label: "",
                      required: false,
                    },
                    [mobileNumber.name]: {
                      value: !sendAsSms.value ? "" : mobileNumber.value,
                      required: sendAsSms.value,
                    },
                  },
                };
                modifyForm({
                  [fuelCodeType.name]: {
                    value,
                  },
                  ...obj[value],
                });
              }}
              disabled={noProductCode || fuelCodeType.disabled}
            />
          </Field>
          {(fuelCodeType.value === FuelCodeType.DriverSpecific ||
            fuelCodeType.value === FuelCodeType.DriverVehicleSpecific) && (
            <Field label={locale.assignedDriver}>
              <SelectDriver
                {...fields.driverIds}
                onChange={modifyField}
                disabled={noProductCode || fuelCodeType.disabled}
              />
            </Field>
          )}
          {(fuelCodeType.value === FuelCodeType.VehicleSpecific ||
            fuelCodeType.value === FuelCodeType.DriverVehicleSpecific) && (
            <Field label={locale.assignedVehicle}>
              <SelectVehicle
                {...fields.vehicleIds}
                onChange={modifyField}
                disabled={noProductCode || fuelCodeType.disabled}
              />
            </Field>
          )}
        </div>

        <div className={styles.checkboxes}>
          <Checkbox
            {...autoRenew}
            onChange={(name, { value }) => {
              modifyForm({
                [name]: {
                  value,
                },
              });
            }}
            disabled={noProductCode || autoRenew.disabled}
          />
          <locale.Populate
            text={locale.autoRenewFuelCode}
            items={[<b>{duration.value} day(s)</b>]}
          />
        </div>

        <div className={classNames(styles.checkboxes, styles.limit)}>
          <Checkbox
            {...limitOneTime}
            onChange={modifyField}
            disabled={noProductCode || limitOneTime.disabled}
          />
          <div>
            <div>{limitOneTime.label}</div>
            {limitOneTime.infoMessage ? (
              <div className="gray">{limitOneTime.infoMessage}</div>
            ) : null}
          </div>
        </div>

        <div className={styles.checkboxes}>
          <Checkbox
            {...sendAsSms}
            onChange={(name, { value }) => {
              modifyForm({
                [name]: {
                  value,
                },
                [mobileNumber.name]: {
                  value: "",
                  required: value && hasMobileNumberInput,
                },
              });
            }}
            disabled={noProductCode || sendAsSms.disabled}
          />
          {sendAsSms.label}
        </div>

        {hasMobileNumberInput && sendAsSms.value && (
          <Field {...mobileNumber} className={styles.mobileField}>
            <TextField
              {...mobileNumber}
              onChange={modifyField}
              onBlur={validateField}
              disabled={noProductCode}
            />
          </Field>
        )}
        {!saveTemplate.hidden && (
          <div className={styles.checkboxes}>
            <Checkbox {...saveTemplate} onChange={modifyField} disabled={noProductCode} />
            {saveTemplate.label}
          </div>
        )}
      </div>

      <ActionButton
        right
        loading={loading || (!productCode.creditPrice && productCode.value)}
        items={[
          {
            onClick: () => {
              close();
            },
            text: locale.cancel,
          },
          {
            onClick: () => {
              submitForm(() => {
                const values = getFormValues();
                submit({
                  ...values,
                  volumeRedeemLimit: parseLiter(values.literVolume),
                  startDate: values.period.startDate,
                  endDate: values.period.endDate,
                  maxUsage: values.limitOneTime ? 1 : 0,
                  sendSms: values.sendAsSms,
                  autoRenew: values.autoRenew,
                  durationDays: parseInt(values.duration),
                  driverId: values.driverIds,
                  vehicleId: values.vehicleIds,
                  driverName: fields.driverIds.label,
                  vehicleName: fields.vehicleIds.label,
                  driverMobileNumber: fields.driverIds.driverMobileNumber,
                });
              });
            },
            disabled: !isFormSubmittable,
            text: actionText,
          },
        ]}
      />
    </div>
  );
};

export default FuelCodeForm;
