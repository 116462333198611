const Path = {
  Slash: "/",
  Auth: "/auth",
  Activity: "/activity",
  Cash: "/cash",
  Dashboard: "/dashboard",
  Download: "/download",
  Fuel: "/fuel",
  Station: "/station",
  Inbox: "/inbox",
  BuyFuel: "/fuel/buy",
  FuelHistory: "/fuel/buy/history",
  FuelGenerateCodes: "/fuel/generate-code",
  MyFuelCode: "/fuel/code",
  PriceHistory: "/fuel/buy/price-history",
  Driver: "/activity/driver",
  Vehicle: "/activity/vehicle",
  Redemption: "/activity/redemption",
  FleetCardRedemptionHistory: "/activity/redemption-fleet-card",
  Groups: "/activity/groups",
  CashIn: "/cash/cashin",
  CashInHistory: "/cash/cashin/history",
  ActivateAccount: "/activate-account",
  StationList: "/station/list",
  Redirect: "/redirect",
  Paymaya: "/paymaya",
  Brankas: "/brankas",
  Success: "/success",
  Failure: "/failure",
  Failed: "/failed",
  Cancel: "/cancel",
  BankCashIn: "/cashin",
  OtherServices: "/other-services",
  Loans: "/other-services/loans",
  SelectUserFleet: "/select-fleet",
  Promo: "/promos",
  ForgotPassword: "/auth/forgot",
  ResetPassword: "/auth/reset",
  AccessAuthorization: "/access-authorization",
  UserAccess: "/access-authorization/user-access",
};

export default Path;
