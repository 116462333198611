import React from "react";
import { Autocomplete as MaterialAutocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import styles from "./autocomplete.module.scss";
import classNames from "classnames";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import { Checkbox } from "components/commons";

import { useCallback } from "react";
import { useMemo } from "react";
import { CircularProgress } from "@mui/material";

const Autocomplete = ({
  value = null || [],
  options = [],
  onChange,
  name,
  placeholder = "Select",
  disabled,
  onInputChange,
  multiple = false,
  hasAll = false,
  className,
  capitalize = true,
  error,
  loading,
}) => {
  const onChangeCb = useCallback(
    (e, valObj) => {
      if (onChange) {
        return onChange(name, { value: valObj?.value || null });
      }
    },
    [onChange, name]
  );

  const isSelectedAll = useMemo(() => {
    if (multiple && hasAll) {
      if (value.length === options.length) {
        return true;
      }
    }
    return false;
  }, [value, multiple, hasAll, options.length]);

  const dropdownOptions = useMemo(() => {
    if (hasAll) {
      return [{ label: "All", value: "all" }, ...options];
    }
    return options;
  }, [options, hasAll]);

  if (multiple) {
    return (
      <div className={className}>
        <FormControl
          error={error}
          loading={loading}
          variant="outlined"
          className={classNames(styles.multipleAutocomplete)}
        >
          <Select
            loading={loading}
            className={classNames({
              [`${styles.error}`]: error,
            })}
            error={error}
            name={name}
            multiple
            value={value || []}
            // placeholder={placeholder}
            onChange={
              hasAll
                ? (obj) => {
                    const value = obj?.target?.value;
                    if (
                      value.includes("all") ||
                      (isSelectedAll && value.length !== options.length - 1) ||
                      (value.length === 1 && value[0] === "all")
                    ) {
                      onChange(name, {
                        value: isSelectedAll ? [] : options.map((option) => option.value),
                      });
                    } else {
                      onChange(name, {
                        value: value,
                      });
                    }
                  }
                : onChange
            }
            renderInput={() => {
              if (!value.length || !value) {
                return placeholder;
              }
            }}
            renderValue={(selected) => {
              if (isSelectedAll) {
                return "All";
              }
              let values = [...selected];
              const index = values.indexOf("all");
              if (index !== -1) {
                values = ["all"];
              }

              const normalizeValue = {};
              dropdownOptions.forEach((o) => {
                normalizeValue[o.value] = o.label;
              });

              return (
                <span className={capitalize ? styles.selectedItems : null}>
                  {values.map((v) => normalizeValue[v]).join(", ")}
                </span>
              );
            }}
            renderSuffix={loading ? <CircularProgress /> : null}
          >
            {dropdownOptions.map((name) => (
              <MenuItem key={name.value} value={name.value}>
                <Checkbox value={isSelectedAll || value.indexOf(name.value) > -1} />
                <ListItemText primary={name.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  } else {
    return (
      <div className={className}>
        <MaterialAutocomplete
          error={error}
          value={value}
          loading={loading}
          getOptionSelected={(option, value) => {
            return option.value === value;
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              const label = options.find((o) => o.value === option)?.label;
              if (label) {
                return label;
              }
            }
            return option.label ? option.label : "";
          }}
          className={classNames(styles.autocomplete, {
            [styles.disabled]: disabled,
          })}
          options={options}
          onChange={onChangeCb}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant="outlined"
                loading={loading}
                placeholder={loading ? "Loading..." : placeholder}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: loading ? (
                    <div
                      style={{
                        width: "100%",
                        position: "absolute",
                        paddingRight: "20px",
                        textAlign: "right",
                      }}
                    >
                      <CircularProgress size={18} />
                    </div>
                  ) : (
                    params.InputProps?.endAdornment
                  ),
                }}
              />
            );
          }}
          ListboxProps={{
            style: { fontSize: "14px" },
            renderSuffix: loading ? <CircularProgress /> : null,
          }}
          disabled={disabled}
          onInputChange={onInputChange}
          renderSuffix={loading ? <CircularProgress /> : null}
        />
      </div>
    );
  }
};

export default Autocomplete;
