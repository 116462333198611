import classNames from "classnames";
import React, { useCallback, useState, useMemo } from "react";
import { Dropdown } from "..";
import { Popper, Fade } from "@material-ui/core";
import { DateRangePicker as DateRangePickerComponent } from "react-date-range";
import styles from "./date-range-picker.module.scss";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; //
import moment from "moment";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const DateRangePicker = ({
  className,
  startDate = moment(),
  endDate = moment(),
  onChange,
  name,
  minDate,
  isRangeDisablerActive = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const dateRangeValue = useMemo(() => {
    const dateDiff = moment(endDate).diff(moment(startDate), "days");
    const dateRanges = {
      31: "4weeks",
      184: "6months",
      183: "6months",
      365: "12months",
    };
    return dateRanges[dateDiff.toString()] || "custom";
  }, [startDate, endDate]);

  const invalidRangeSelection = useCallback(
    (startDateDays) => {
      const onboardedDays = moment(minDate).diff(moment(), "days");
      return isRangeDisablerActive && Math.abs(onboardedDays) < startDateDays;
    },
    [minDate, isRangeDisablerActive]
  );

  const dateRangeDropdownValue = useMemo(() => {
    const range = moment(endDate).diff(moment(startDate), "days");
    const obj = {
      181: "6months",
      182: "6months",
      30: "4weeks",
      31: "4weeks",
      365: "12months",
    };
    if (obj[range]) {
      return obj[range];
    }
    return "custom";
  }, [endDate, startDate]);

  return (
    <div className={classNames(className)}>
      {open && (
        <div
          className={styles.dimmer}
          onClick={() => {
            setOpen(false);
            setActive(false);
          }}
        ></div>
      )}
      <div className={styles.datePicker}>
        <Popper open={open} anchorEl={anchorEl} placement={"right-end"} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div className={styles.datePickerContainer}>
                <DateRangePickerComponent
                  staticRanges={[]}
                  inputRanges={[]}
                  onChange={(item) => {
                    onChange(name, {
                      startDate: item.selection.startDate,
                      endDate: item.selection.endDate || item.selection.startDate,
                    });
                  }}
                  showSelectionPreview={false}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={[
                    {
                      startDate: new Date(startDate),
                      endDate: new Date(endDate),
                      key: "selection",
                    },
                  ]}
                  direction="horizontal"
                  minDate={minDate ? minDate : ""}
                  maxDate={moment().toDate()}
                />
              </div>
            </Fade>
          )}
        </Popper>
      </div>
      <div
        onClick={() => {
          if (!active) {
            setActive(true);
          }
        }}
      >
        <Dropdown
          value={dateRangeDropdownValue}
          open={active}
          disableScrollLock
          options={[
            {
              label: "Last 4 weeks",
              value: "4weeks",
              disabled: invalidRangeSelection(30),
            },
            {
              label: "Last 6 months",
              value: "6months",
              disabled: invalidRangeSelection(183),
            },
            {
              label: "Last 12 months",
              value: "12months",
              disabled: invalidRangeSelection(365),
            },
            {
              label: (
                <div
                  className={styles.dateRange}
                  id="DateRangePicker"
                  onClick={(e) => {
                    setOpen(true);
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  Date Range <ChevronRightIcon className={styles.icon} />
                </div>
              ),
              value: "custom",
            },
          ]}
          renderValue={(render) => {
            const range = moment(endDate).diff(moment(startDate), "days");
            const obj = {
              181: "Last 6 months",
              182: "Last 6 months",
              30: "Last 4 weeks",
              31: "Last 4 weeks",
              365: "Last 12 months",
            };
            if (obj[range]) {
              return obj[range];
            }
            if (dateRangeValue === "custom") {
              return `${moment(startDate).format("MMM DD")} - ${moment(endDate).format(
                "MMM DD, YYYY"
              )}`;
            }
            return {
              "4weeks": "Last 4 weeks",
              "6months": "Last 6 months",
              "12months": "Last 12 months",
            }[render];
          }}
          onClick={(value, e) => {
            const isCustom = value === "custom";

            if (value) {
              setActive(isCustom && !open ? true : false);
              if (!isCustom) {
                const dateRanges = {
                  "4weeks": [1, "months"],
                  "6months": [6, "months"],
                  "12months": [12, "months"],
                }[value];

                if (dateRanges) {
                  onChange(name, {
                    startDate: moment().subtract(dateRanges[0], dateRanges[1]),
                    endDate: moment(),
                  });
                }
              }
            } else {
              if (!e.target.id) {
                setActive(false);
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
