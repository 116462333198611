import React, { useContext, useMemo, useState } from "react";
import { Intro, Text, Button, Image } from "components/commons";
import { ErrorCode, Path, FleetStatus, Event, Page, UserAccess } from "enums";
import locale from "localization";
import styles from "./buy-fuel.module.scss";
import { NavLink, useHistory } from "react-router-dom";
import { SelectFuel, InputFuelAmount } from "components/field";
import { History } from "images";
import { useApi, useForm, useModal, useMount } from "hooks";
import { initialState as formState } from "./buy-fuel.state";
import ConfirmBuyFuel from "./confirm-buy-fuel";
import { buyFuel } from "apis/buy-fuel.api";
import { FleetContext, AnalyticsContext, UserContext } from "contexts";
import { formatVolume, handleRequest, parseNumber, roundUp } from "utils";
import ResultBuyFuel from "./result-buy-fuel";
import { getFuelBalance } from "apis/fuel-balance.api";
import { getWallet } from "apis/wallet.api";
import Big from "big.js";

const BuyFuelModule = ({ initialState }) => {
  const { page, track } = useContext(AnalyticsContext);
  const { userAccess } = useContext(UserContext);
  const { fleet } = useContext(FleetContext);
  const { fleetId, status } = fleet;
  const deactivated = status === FleetStatus.Deactivated;
  const history = useHistory();
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);


  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.BuyFuel;
  });
  const confirmModal = useModal();
  const transactionModal = useModal();
  const [insufficientBalance, setInsufficientBalance] = useState(false);

  const {
    request: getFuelBalanceRequest,
    loading: fetchingFuelBalance,
    result: fuelBalance = [],
  } = useApi({
    api: getFuelBalance,
    params: {
      fleetId,
    },
    pageError: true,
  });

  const {
    request,
    loading,
    result = {},
  } = useApi({
    api: buyFuel,
    params: {
      fleetId,
    },
    handleOwnError: true,
  });

  const { fields, modifyField, isFormSubmittable, submitForm, clearForm, modifyForm } = useForm({
    initialState: form,
  });

  const buyTypeField = {
    ...fields.buyType,
    onChange: modifyField,
  };

  const amountField = {
    ...fields.amount,
    onChange: modifyField,
  };

  const literVolumeField = {
    ...fields.literVolume,
    onChange: modifyField,
  };

  const {
    request: getWalletBalanceRequest,
    loading: fetchingWalletBalance,
    result: wallet = {},
  } = useApi({
    api: getWallet,
    params: {
      fleetId,
    },
    pageError: true,
  });

  useMount(() => {
    getWalletBalanceRequest();
    page({
      name: Page.ViewBuyFuel,
    });
  });

  const liter = useMemo(() => {
    const type = fields.buyType;
    const amount = fields.amount;
    const productCode = fields.productCode;
    const literVolume = fields.literVolume;
    if (type.value === "peso") {
      return Number(parseNumber(amount.value)) / productCode.creditPrice;
    }
    return Number(parseNumber(literVolume.value));
  }, [fields]);

  const total = useMemo(() => {
    const type = fields.buyType;
    const amount = fields.amount;
    const productCode = fields.productCode;
    const literVolume = fields.literVolume;
    if (type.value === "peso") {
      return Number(parseNumber(amount.value));
    }
    return Number(parseNumber(literVolume.value)) * productCode.creditPrice;
  }, [fields]);

  const submit = () => {
    handleRequest(async () => {
      try {
        const params = {
          liter: fields.buyType.value === "liters" ? roundUp(liter) : null,
          amount: fields.buyType.value === "peso" ? roundUp(total) : null,
          productCode: fields.productCode.value,
        };
        const res = await request(params, submit);
        track(Event.BuyFuel, params);
        confirmModal.close();
        transactionModal.show({
          status: "success",
          buyFuelId: res.buyFuelId,
          productCode: fields.productCode.value,
          amount: fields.amount.value,
          literVolume: liter,
        });
        await getFuelBalanceRequest();
        await getWalletBalanceRequest();
      } catch (err) {
        let message = locale.errorProcessingPayment;
        confirmModal.close();

        switch (err.data?.errorCode) {
          case ErrorCode.InsufficientAmount:
            message = locale.youHaveInsufficientBalance;
            break;
          case ErrorCode.BuyFuelLimitExceeded:
            message = locale.populate(locale.dueToCurrentMarket, [
              formatVolume(err.data.remainingAmount),
            ]);
            break;
          default:
            message = locale.errorProcessingPayment;
            break;
        }

        transactionModal.show({
          status: "failed",
          productCode: fields.productCode.value,
          message,
          amount: fields.amount.value,
          literVolume: liter,
        });
      }
    });
  };

  const currentLiterBalance = useMemo(() => {
    let clb = Big(0);
    if (fuelBalance.length > 0) {
      fuelBalance.forEach((fb) => {
        clb = Big(clb).add(Big(fb.fuelBalance));
      });
    }
    return formatVolume(clb.toNumber());
  }, [fuelBalance]);

  const hasModifyAccess = getUserAccess?.fullAccess || getUserAccess?.modifyAccess ? false : true;

  return (
    <div>
      <div className={styles.intro}>
        <Intro
          title={locale.buyFuel}
          subtitle={locale.pleaseSelectProductYouWish}
          actionText={getUserAccess?.viewAccess || getUserAccess?.fullAccess ? locale.buyFuelHistoryCapitalize : ""}
          actionOnClick={() => {
            history.push(Path.FuelHistory);
          }}
          actionOutlined
          actionStartIcon={<Image src={History} />}
        />
      </div>
      <div style={{ marginTop: "30px", marginBottom: "20px" }}>
        <div style={{ fontWeight: "bold" }}>
          {Boolean(fleet?.inventoryLimit) && (
            <locale.Populate
              text={locale.fuelInventoryLimit}
              items={[formatVolume(fleet.inventoryLimit)]}
            />
          )}
        </div>
        <div style={{ marginTop: "5px", fontWeight: "bold" }}>
          {currentLiterBalance && (
            <locale.Populate text={locale.currentBalanceLimit} items={[currentLiterBalance]} />
          )}
        </div>
      </div>
      <div className={styles.selectFuel}>
          <div className={styles.header}>
            <Text header bolder>
              {locale.stepOneSelectFuel}
            </Text>
            <NavLink to={Path.PriceHistory}>
              <Button disabled={getUserAccess?.fullAccess || getUserAccess?.viewAccess ? false : true }link eventName={Event.ViewPriceHistory}>
                {locale.viewPriceHistory}
              </Button>
            </NavLink>
          </div>
          <SelectFuel
            vertical
            withPrice
            onChange={modifyField}
            {...fields.productCode}
            getFuelBalanceRequest={getFuelBalanceRequest}
            fetchingFuelBalance={fetchingFuelBalance}
            fuelBalance={fuelBalance}
            disabled={deactivated || hasModifyAccess}

          />
      </div>
      <div className={styles.enterAmount}>
        <div className={styles.header}>
          <Text header bolder>
            {locale.stepTwoEnterAmount}
          </Text>
        </div>
        <InputFuelAmount
          className={styles.inputFuelAmountContainer}
          type={buyTypeField}
          amount={amountField}
          productCode={fields.productCode}
          literVolume={literVolumeField}
          modifyForm={modifyForm}
          setInsufficientBalance={setInsufficientBalance}
          fetchingWalletBalance={fetchingWalletBalance}
          walletBalance={!fetchingWalletBalance ? wallet.walletBalance : 0}
          disabled={deactivated || hasModifyAccess}
        />
      </div>
      <Button
        className={styles.continue}
        primary
        disabled={!isFormSubmittable || insufficientBalance || deactivated || hasModifyAccess}
        onClick={() => {
          confirmModal.show();
        }}
      >
        {locale.continue}
      </Button>
      <ConfirmBuyFuel
        {...confirmModal}
        type={buyTypeField}
        amount={amountField}
        productCode={fields.productCode}
        literVolume={literVolumeField}
        liter={liter}
        purchase={() => submitForm(submit)}
        loading={loading}
        total={total}
      />
      <ResultBuyFuel
        {...transactionModal}
        close={() => {
          transactionModal.close();
        }}
        clear={() => {
          clearForm();
          transactionModal.close();
        }}
        loading={loading}
        type={buyTypeField}
        total={result?.totalCreditAmount || total}
        // literVolume={result?.literVolume || liter}
        productCode={fields.productCode}
        purchase={() => submitForm(submit)}
      />
    </div>
  );
};

export default BuyFuelModule;
