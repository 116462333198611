import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core";
import { useState, useCallback } from "react";
import { DefaultProfile, PLBLogoWhite } from "../../../images";
import Image from "../image/image";
import styles from "./header.module.scss";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { useApi } from "hooks";
import { redirectTo } from "utils";
import { logout } from "apis";
import { Path, FleetStatus } from "enums";
import { useContext } from "react";
import { AppContext, FleetContext, MessagesContext } from "contexts";
import locale from "localization";
import { useHistory } from "react-router-dom";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import IconNotificationCount from "../icon-notification-count/icon-notification-count";
import { EmailOutlined } from "@material-ui/icons";

const Header = ({ setNewNotification, newNotification }) => {
  const [open, setOpen] = useState(false);
  const { toggleLoading } = useContext(AppContext);
  const { fleet = {} } = useContext(FleetContext) || {};
  const { status, businessName } = fleet || {};
  const messages = useContext(MessagesContext);

  const { request: logoutRequest } = useApi({
    api: logout,
  });

  const history = useHistory();

  const logoutCb = useCallback(async () => {
    toggleLoading(true);
    await logoutRequest();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("fleetId");
    localStorage.removeItem("singleUserFleet");
    localStorage.removeItem("role");
    toggleLoading(false);
    redirectTo(Path.Auth);
  }, [logoutRequest, toggleLoading]);

  const redirectToSelectFleet = useCallback(() => {
    if (history) {
      history.push(Path.SelectUserFleet);
    }
  }, [history]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div id="header">
      <AppBar className={styles.headerContainer}>
        <Toolbar disableGutters>
          <div className={styles.logoContainer}>
            <Image src={PLBLogoWhite} className={styles.logo} />
          </div>
          <div className={styles.toolbarContainer}>
            <Typography variant="h6" className={styles.title}>
              {status === FleetStatus.Deactivated
                ? `${businessName} - (${locale.deactivated})`
                : `${businessName ? businessName : ""}`}
            </Typography>
            <div className={styles.menuContainer}>
              <IconButton
                aria-controls="menu-appbar"
                color={"primary"}
                aria-haspopup="true"
                onClick={() => {
                  setNewNotification();
                  history.push(Path.Download);
                }}
              >
                {newNotification && <div className={styles?.notificationIcon} />}
                <GetAppRoundedIcon className={styles.downloadIcon} />
              </IconButton>
              {messages && (
                <IconNotificationCount
                  icon={EmailOutlined}
                  count={messages.unreadMessages}
                  onClick={() => {
                    history.push(Path.Inbox);
                  }}
                />
              )}

              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpen}
              >
                <Image src={DefaultProfile} className={styles.profile} />
                <KeyboardArrowDownRoundedIcon className={styles.caretIcon} />
              </IconButton>
              <Menu
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                open={open}
                className={styles.menu}
                onClose={handleClose}
              >
                {localStorage.getItem("multipleFleet") === "true" && (
                  <MenuItem className={styles.menuItem} onClick={redirectToSelectFleet}>
                    {" "}
                    Select Fleet{" "}
                  </MenuItem>
                )}
                <MenuItem className={styles.menuItem} onClick={logoutCb}>
                  {" "}
                  Logout{" "}
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
