import {
  getFleetConsumption,
  getFleetSavings,
  getLatestPriceMovements,
  getPriceMovements,
  getPriceHistory,
  getUpcomingPriceMovements,
  getTotalLitersMyActiveFuelCode,
} from "apis";
import { getFuelBalance } from "apis/fuel-balance.api";
import { linkVipBiz, getLoyaltyPoints } from "apis/vip-biz.api";
import { getWallet } from "apis/wallet.api";
import { getFeedBack, createFeedBack } from "apis/feedback.api";
import BalanceCard from "components/balance-card/balance-card";
import { Intro, Text, Image, TextField, Field, Button, Loader } from "components/commons";
import ConsumptionCard from "components/consumption-card/consumption-card";
import PriceMovementChart from "components/price-movement-chart/price-movement-chart";
import PriceMovement from "components/price-movement/price-movement";
import UpcomingPriceMovement from "components/upcoming-price-movement/upcoming-price-movement";
import { ConfirmModal, SuccessModal } from "components/modals";
import { AnalyticsContext, FleetContext, UserContext } from "contexts";
import {
  Balance,
  DateTime,
  FuelType,
  Page,
  PriceMovementGraphType,
  Path,
  Products,
  UserAccess,
  PLBTypes,
} from "enums";
import { useApi, useFilter, useMount, useForm, useModal } from "hooks";
import locale from "localization";
import { find } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { formatAmount, formatCurrency, handleRequest } from "utils";
import DashboardFilter from "./dashboard-filter";
import DashboardChartHeader from "./dashboard-chart-header";
import { dashboardFilterState } from "./dashboard-filter.state";
import styles from "./dashboard.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import SurveyUnsatisfactoryForm from "./survey-unsatisfactory/survey-unsatisfactory-form";
import SurveySatisfactoryForm from "./survey-satisfactory/survey-satisfactory-form";
import ReferPLBFriendForm from "./refer-plb-friend/refer-plb-friend-form";
import {
  latestPriceMovementObject,
  upcomingPriceMovementObject,
  priceMovementObject,
} from "./dashboard-object.utils";
import { useHistory } from "react-router-dom";
import { LinkVIP, UnLinkVIP, PhilippinesFlag, LOCQPayBalance } from "images";
import { initialState as formState } from "./vip-biz/vip-details.state";
import { capitalize } from "utils";
import { prettifyFeedback } from "utils/pretty.utils";
import CancelIcon from "@material-ui/icons/Cancel";

const DashboardModule = () => {
  const { page } = useContext(AnalyticsContext);
  const { fleet } = useContext(FleetContext);
  const { user, userAccess } = useContext(UserContext);
  const { fleetId, dateTimeOnboarded } = fleet;
  const { modifyFilters, filterState } = useFilter(dashboardFilterState(dateTimeOnboarded));
  const [toolTipData, setToolTipData] = useState(null);
  const [graphData, setGraphData] = useState({});
  const [loyaltyError, setLoyaltyError] = useState(false);
  const history = useHistory();
  const [rating, setRating] = useState(0);
  const [, setHover] = useState(0);
  const [satisfactory, setSatisfactory] = useState(false);
  const [showStar, setShowStar] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step2Data, setStep2Data] = useState({});
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const confirmModal = useModal();
  const successModal = useModal();

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.Dashboard;
  });

  const disabledLinkVIPFields =
    getUserAccess?.fullAccess || getUserAccess?.modifyAccess ? false : true;

  const {
    request: getFuelBalanceRequest,
    loading: fetchingFuelBalance,
    result: fuelBalance = [],
  } = useApi({
    api: getFuelBalance,
    params: {
      fleetId,
    },
    pageError: true,
  });

  const {
    request: getWalletBalanceRequest,
    loading: fetchingWalletBalance,
    result: wallet = {},
  } = useApi({
    api: getWallet,
    params: {
      fleetId,
    },
    pageError: true,
  });

  const {
    request: getFleetConsumptionRequest,
    loading: fetchingFleetConsumption,
    result: consumption = [],
  } = useApi({
    api: getFleetConsumption,
    params: {
      startDate: moment().startOf("month").format(DateTime.C),
      endDate: moment().endOf("month").format(DateTime.D),
      fleetId,
    },
    pageError: true,
  });

  const {
    request: getTotalLitersMyActiveFuelCodeRequest,
    loading: fetchingTotalLitersMyActiveFuelCode,
    result: totalLiters = [],
  } = useApi({
    api: getTotalLitersMyActiveFuelCode,
    params: {
      startDate: moment().startOf("month").format(DateTime.C),
      endDate: moment().endOf("month").format(DateTime.D),
      fleetId,
    },
    pageError: true,
  });

  const {
    request: getFleetSavingsRequest,
    loading: fetchingFleetSavings,
    result: fleetSavings = { savings: 0 },
  } = useApi({
    api: getFleetSavings,
    params: {
      fleetId,
    },
    pageError: false,
  });

  const { request: getPriceMovementRequest, loading: fetchingPriceMovement } = useApi({
    api: getPriceMovements,
    params: {
      endDate: moment().format(DateTime.D),
    },
    pageError: false,
  });

  const { request: getPriceHistoryRequest, loading: fetchingPriceHistory } = useApi({
    api: getPriceHistory,
    pageError: false,
  });

  const {
    request: getLatestPriceMovementRequest,
    loading: fetchingLatestPriceMovement,
    result: nationalLatestPriceMovement = { priceMovements: {} },
  } = useApi({
    api: getLatestPriceMovements,
    pageError: false,
  });

  const {
    request: getUpcomingPriceMovementRequest,
    loading: fetchingUpcomingPriceMovement,
    result: upcomingPriceMovement = { priceMovements: {} },
  } = useApi({
    api: getUpcomingPriceMovements,
    pageError: false,
  });

  const addFeedBackRequest = useApi({
    api: createFeedBack,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: getFleetFeedback, loading: fetchingFeedbackRequest } = useApi({
    api: getFeedBack,
    pageError: false,
    modalError: false,
    params: {
      fleetId,
    },
  });

  const { request: getLoyaltyPointsRequest, loading: loadingLoyaltyPoints } = useApi({
    api: getLoyaltyPoints,
    params: {
      fleetId,
    },
    modalError: false,
  });

  useMount(() => {
    getFuelBalanceRequest();
    getWalletBalanceRequest();
    getFleetConsumptionRequest();
    getTotalLitersMyActiveFuelCodeRequest();
    getFleetSavingsRequest();
    // getPriceMovementRequest();
    getLatestPriceMovementRequest();
    // getPriceHistoryRequest(priceHistoryQuery);

    fetchPriceData({
      startDate: filterState.startDate,
      endDate: filterState.endDate,
      graphType: filterState.graphType,
    });

    fetchFleetFeedback();
    getUpcomingPriceMovementRequest();
    page({
      name: Page.ViewDashboard,
    });
  });

  const priceHistoryQuery = useMemo(() => {
    return {
      fleetId,
      movementType: "national,account,initial",
    };
  }, [fleetId]);

  const prepareGraphData = useCallback((data, graphType) => {
    // const { priceMovements } = nationalPriceMovement;
    // const { priceHistory } = getPriceHistoryResult;
    // const price =
    //   graphType === PriceMovementGraphType.contractPrices ? priceHistory : priceMovements;

    setGraphData(priceMovementObject({ price: data, graphType }));
  }, []);

  const preparedLatestPriceMovement = useMemo(() => {
    const { priceMovements } = nationalLatestPriceMovement;
    return latestPriceMovementObject({ priceMovements });
  }, [nationalLatestPriceMovement]);

  const preparedUpcomingPriceMovement = useMemo(() => {
    const { priceMovements } = upcomingPriceMovement;
    return upcomingPriceMovementObject({ priceMovements });
  }, [upcomingPriceMovement]);

  // const onDateRangeCb = useCallback(
  //   async ({
  //     startDate = filterState?.startDate,
  //     endDate = filterState?.endDate,
  //     graphType = filterState?.graphType,
  //     offsetStartDate = false,
  //   }) => {
  //     if (!offsetStartDate) {
  //       modifyFilters({ startDate, endDate, graphType });
  //     }
  //     if (startDate) {
  //       const params = {
  //         startDate: moment(startDate).format(DateTime.C),
  //         endDate: moment(endDate).format(DateTime.D),
  //         graphType,
  //       };
  //       await getPriceHistoryRequest({
  //         ...priceHistoryQuery,
  //         ...params,
  //       });
  //       setToolTipData(null);
  //       const result = await getPriceMovementRequest({ ...params });
  //       if (
  //         result?.priceMovements?.length > 0 &&
  //         !offsetStartDate &&
  //         graphType !== PriceMovementGraphType.contractPrices
  //       ) {
  //         const earliestRecordStartDate = moment(result?.priceMovements[0]?.effectiveAt);
  //         const earlyStartDate = moment(startDate).isBefore(earliestRecordStartDate, "day");

  //         if (earlyStartDate) {
  //           onDateRangeCb({
  //             startDate: moment(startDate).subtract(1, "week"),
  //             endDate,
  //             offsetStartDate: true,
  //           });
  //         }
  //       }
  //     }
  //   },
  //   [
  //     filterState?.endDate,
  //     filterState?.graphType,
  //     filterState?.startDate,
  //     getPriceHistoryRequest,
  //     getPriceMovementRequest,
  //     modifyFilters,
  //     priceHistoryQuery,
  //   ]
  // );

  const fetchFleetFeedback = useCallback(async () => {
    const result = await getFleetFeedback();
    if (result && result?.createdAt) {
      if (result?.referToFriend) {
        if (moment().isAfter(moment(result.createdAt).add(30, "days"))) {
          setShowSurvey(true);
        }
      } else {
        if (moment().isAfter(moment(result.createdAt).add(3, "days"))) {
          setShowSurvey(true);
        }
      }
    } else {
      setShowSurvey(true);
    }
  }, [getFleetFeedback]);

  const fetchPriceData = useCallback(
    async ({ startDate, endDate, graphType }) => {
      const params = {
        startDate: moment(startDate).format(DateTime.C),
        endDate: moment(endDate).format(DateTime.D),
        graphType,
      };

      let res = [];

      if (graphType === PriceMovementGraphType.contractPrices) {
        const { priceHistory } = await getPriceHistoryRequest({
          ...priceHistoryQuery,
          ...params,
        });
        res = priceHistory;
      } else {
        const { priceMovements } = await getPriceMovementRequest({ ...params });
        res = priceMovements;
      }
      prepareGraphData(res, graphType);
      setToolTipData(null);
    },
    [getPriceHistoryRequest, getPriceMovementRequest, prepareGraphData, priceHistoryQuery]
  );

  const onDateRangeCb = useCallback(
    async ({ startDate, endDate }) => {
      const { graphType } = filterState;
      modifyFilters({ startDate, endDate });

      fetchPriceData({ startDate, endDate, graphType });
    },
    [filterState, modifyFilters, fetchPriceData]
  );

  const onClickProduct = useCallback(
    async (productCode) => {
      history.push(Path.MyFuelCode, { productCode });
    },
    [history]
  );

  const onGraphTypeCb = useCallback(
    (value) => {
      const { startDate, endDate } = filterState;

      modifyFilters({ graphType: value });

      fetchPriceData({ startDate, endDate, graphType: value });

      // let filters = { graphType: value };
      // if (value === PriceMovementGraphType.contractPrices) {
      //   filters = {
      //     ...filters,
      //     startDate: moment(dateTimeOnboarded).toDate(),
      //     endDate: moment().toDate(),
      //   };
      // }
      // onDateRangeCb(filters);
    },
    [filterState, modifyFilters, fetchPriceData]
  );

  const toolTipCB = useCallback(
    (value) => {
      setToolTipData(value[0]);
    },
    [setToolTipData]
  );

  const submitSurvey = (valuesStep3) => {
    const feedbackValues = [];
    for (const [key, value] of Object.entries(step2Data)) {
      if (value) {
        feedbackValues.push(prettifyFeedback(key));
      }
    }
    const referToFriend = [];
    if (!valuesStep3?.isSkip) {
      for (const [key, value] of Object.entries(valuesStep3)) {
        if (value) {
          referToFriend.push(prettifyFeedback(key));
        }
      }
    }

    handleRequest(
      async () => {
        const finalParams = {
          rating,
          feedback: feedbackValues.join(),
          otherReason: step2Data?.otherReasonsDescription,
          referToFriend: referToFriend.join(),
          recommendationScore: 0,
          fleetId,
        };
        await addFeedBackRequest.request(finalParams, () => submitSurvey());
        setSuccessSubmit(true);
        setStep2(false);
        setStep2Data({});
        setRating(0);
      },
      null,
      (err) => {
        if (err.data?.errorCode) {
          setSuccessSubmit(false);
        }
        return err.showError(false);
      }
    );
  };

  const renderRating = () => {
    switch (rating) {
      case 1:
        return (
          <div className={styles.ratingGood}>
            <Button
              className={styles.textRatingGood}
              onClick={() => {
                setSatisfactory(false);
                setShowStar(false);
              }}
            >
              {locale.unsatisfactory}
            </Button>
          </div>
        );
      case 2:
        return (
          <div className={styles.ratingGood}>
            <Button
              className={styles.textRatingGood}
              onClick={() => {
                setSatisfactory(false);
                setShowStar(false);
              }}
            >
              {locale.canBeBetter}
            </Button>
          </div>
        );
      case 3:
        return (
          <div className={styles.ratingGood}>
            <Button
              className={styles.textRatingGood}
              onClick={() => {
                setSatisfactory(false);
                setShowStar(false);
              }}
            >
              {locale.average}
            </Button>
          </div>
        );
      case 4:
        return (
          <div className={styles.ratingGood}>
            <Button
              className={styles.textRatingGood}
              onClick={() => {
                setSatisfactory(true);
                setShowStar(false);
              }}
            >
              {locale.veryGood}
            </Button>
          </div>
        );
      case 5:
        return (
          <div className={styles.ratingGood}>
            <Button
              className={styles.textRatingGood}
              onClick={() => {
                setSatisfactory(true);
                setShowStar(false);
              }}
            >
              {locale.amazing}
            </Button>
          </div>
        );
      default:
        return <Text className={styles.noRating}>{locale.noRatingYet}</Text>;
    }
  };

  const handleSkipOrClose = () => {
    submitSurvey({});
    setRating(0);
    setShowSurvey(false);
  };

  const { request: linkVIPRequest, loading: fetchingLinkVIP } = useApi({
    api: linkVipBiz,
    handleOwnError: {
      badrequest: true,
    },
  });

  const form = useMemo(() => {
    let initialState = {};
    return formState(initialState);
  }, []);

  const {
    fields,
    modifyField,
    modifyForm,
    isFormSubmittable,
    makeFormInvalid,
    submitForm,
    clearForm,
    getFormValues,
    applyFieldErrors,
    validatedField,
  } = useForm({
    initialState: form,
  });

  useMount(() => {
    handleRequest(
      async () => {
        const data = await getLoyaltyPointsRequest();
        if (data) {
          setLoyaltyError(false);
          modifyForm({
            [fields.points.name]: {
              value: data?.points.toString(),
            },
            [fields.firstName.name]: {
              value: data?.firstName,
            },
            [fields.lastName.name]: {
              value: data?.lastName,
            },
            [fields.mobileNumber.name]: {
              value: data?.mobileNumber,
            },
          });
        }
      },
      null,
      (err) => {
        if (err.data?.errorCode) {
          setLoyaltyError(true);
        }
      }
    );
  });

  const submit = (params) => {
    handleRequest(
      async () => {
        confirmModal.close();
        await linkVIPRequest(
          {
            ...params,
          },
          () => handleSubmit()
        );
        successModal.show({
          title: locale.exclamatedSuccess,
          content: locale.successfullyLinkVipBizAccount,
          primary: {
            text: locale.gotIt,
            onClick: () => {
              setLoyaltyError(false);
              clearForm();
              handleRequest(
                async () => {
                  const data = await getLoyaltyPointsRequest();
                  if (data) {
                    modifyForm({
                      [fields.points.name]: {
                        value: data?.points.toString(),
                      },
                      [fields.firstName.name]: {
                        value: data?.firstName,
                      },
                      [fields.lastName.name]: {
                        value: data?.lastName,
                      },
                      [fields.mobileNumber.name]: {
                        value: data?.mobileNumber,
                      },
                    });
                    setLoyaltyError(false);
                    successModal.close();
                  }
                },
                null,
                (err) => {
                  if (err.data?.errorCode) {
                    setLoyaltyError(true);
                  }
                }
              );
            },
          },
        });
      },
      null,
      (err) => {
        setLoyaltyError(true);
        makeFormInvalid(true);
        if (err.data?.errorCode === "L1001") {
          applyFieldErrors({
            mobileNumber: locale.vipAccountAlreadyLinked,
          });
        } else if (err.data?.errorCode === "L1002") {
          applyFieldErrors({
            mobileNumber: locale.vipAccountDoesntExist,
          });
        } else {
          applyFieldErrors({
            mobileNumber: err.data?.message,
          });
        }
      }
    );
  };

  const handleSubmit = () => {
    const { mobileNumber } = getFormValues();

    if (mobileNumber) {
      confirmModal.show({
        title: locale.linkAccountQuestionMark,
        content: locale.linkAccountContent,
        secondary: {
          text: locale.Cancel,
        },
        primary: {
          text: locale.linkAccountButton,
          onClick: () => {
            const params = { mobileNumber: `+63${mobileNumber}`, fleetId };
            submit(params);
          },
        },
      });
    }
  };

  return (
    <div>
      <div>
        <Intro
          title={`${locale.welcome} ${user.firstName ? user.firstName : fleet.businessName}!`}
        />
      </div>
      {fleet.plbType === PLBTypes.PLB_HEDGING && (
        <div className={styles.cardContainer}>
          {Object.keys(Balance).map((key) => {
            const productCode = Balance[key];
            let myBalance = 0;
            if (productCode === Balance.Peso) {
              myBalance = wallet.walletBalance || 0;
            } else {
              const findFuel = find(fuelBalance, (fb) => {
                return fb.productCode === productCode;
              });
              if (findFuel) {
                myBalance = findFuel.fuelBalance;
              }
            }
            return (
              <BalanceCard
                type={productCode}
                balance={myBalance}
                key={key}
                loading={fetchingFuelBalance && fetchingWalletBalance}
              />
            );
          })}
        </div>
      )}

      <div className={styles.cardContainer}>
        <div className={styles.priceMovementContent}>
          {Object.keys(preparedUpcomingPriceMovement).length > 0 && (
            <UpcomingPriceMovement
              className={styles.upcomingPriceMovement}
              loading={fetchingUpcomingPriceMovement}
              priceMovement={preparedUpcomingPriceMovement}
            />
          )}
          <Text className={styles.headerTitle}>{locale.latestPriceMovement}</Text>
          <Text className={styles.headerSubtitle}>
            {moment(preparedLatestPriceMovement.effectiveAt).format(DateTime.I)}
          </Text>
          <div className={styles.priceUpdateSummary}>
            <div className={styles.latestPriceMovement}>
              <PriceMovement
                priceMovement={{ type: FuelType.Diesel, price: preparedLatestPriceMovement.diesel }}
                loading={fetchingLatestPriceMovement}
              />
              <PriceMovement
                priceMovement={{ type: FuelType.Gas, price: preparedLatestPriceMovement.gas }}
                loading={fetchingLatestPriceMovement}
              />
            </div>
            <div className={styles.lifetimeSavings}>
              <Text className={styles.headerSubtitle}>{locale.yourLifetimeSavings}</Text>

              <Text className={styles.amount} bolder label={fleetSavings?.savings <= 0}>
                {fetchingFleetSavings ? (
                  <Skeleton variant="rect" className={styles.loaderSavings} />
                ) : (
                  formatCurrency(fleetSavings?.savings)
                )}
              </Text>
              <Text className={styles.headerSubtitle}>
                <locale.Populate
                  text={locale.learnHowYouCanSaveMore}
                  items={[
                    <b
                      className={styles.link}
                      onClick={() => {
                        window.open(
                          "https://drive.google.com/file/d/1muFDJoRHWpqlLkzf1qzJPMLtpnbdOHWH/view?__sta=BTBHFHHYFBUY%7CTIJ&__stm_medium=email&__stm_source=smartech",
                          "_blank"
                        );
                      }}
                    >
                      {locale.here}
                    </b>,
                  ]}
                />
              </Text>
            </div>
          </div>
          <div className={styles.priceMovementHistory}>
            <Text className={styles.headerTitle}>{locale.priceMovementHistory}</Text>
          </div>
          <DashboardFilter
            filterState={filterState}
            onGraphTypeChange={onGraphTypeCb}
            onDateRangeChange={onDateRangeCb}
            earliestRecord={
              filterState.graphType === PriceMovementGraphType.contractPrices
                ? moment(dateTimeOnboarded).toDate()
                : moment("2020-05-12").toDate()
            } // switch date onboard for contract price and earliest price movement recorded for price movement
          />
          {Object.keys(graphData).length > 0 && (
            <DashboardChartHeader
              data={graphData}
              toolTipData={toolTipData}
              loading={fetchingPriceMovement}
            />
          )}

          {fetchingPriceMovement || fetchingPriceHistory ? (
            <Skeleton />
          ) : (
            Object.keys(graphData).length > 0 && (
              <PriceMovementChart
                // fetchingPriceMovement={fetchingPriceMovement}
                data={graphData}
                toolTip={toolTipCB}
                filterState={filterState}
              />
            )
          )}
        </div>
        <div className={styles.rightCardsSection}>
          {fleet.plbType === PLBTypes.PLB_HEDGING && (
            <>
              <div className={styles.activeFuelCodeContent}>
                <Text className={styles.headerTitle}>{locale.activeFuelCodeTotalLiters}</Text>

                <div className={styles.body}>
                  {Object.keys(Balance).map((key) => {
                    const productCode = Balance[key];
                    if (productCode !== Balance.Peso) {
                      let myBalance = 0;
                      switch (productCode) {
                        case Products.Diesel:
                          myBalance = totalLiters.totalFuelCodeDiesel;
                          break;
                        case Products.Gas91:
                          myBalance = totalLiters.totalFuelCodeGas91;
                          break;
                        case Products.Gas95:
                          myBalance = totalLiters.totalFuelCodeGas95;
                          break;
                        default:
                          myBalance = totalLiters.totalFuelCodeGas97;
                      }

                      return (
                        <ConsumptionCard
                          type={productCode}
                          balance={myBalance}
                          key={key}
                          loading={fetchingTotalLitersMyActiveFuelCode}
                          clickProduct={onClickProduct}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
              <div className={styles.consumptionContent}>
                <Text className={styles.headerTitle}>{locale.yourFleetConsumptionForTheMonth}</Text>
                <Text className={styles.headerSubtitle}>{moment().format(DateTime.F)}</Text>

                <div className={styles.body}>
                  {Object.keys(Balance).map((key) => {
                    const productCode = Balance[key];
                    if (productCode !== Balance.Peso) {
                      let myBalance = 0;
                      const findConsumption = find(consumption, (c) => {
                        return c.productCode === productCode;
                      });
                      if (findConsumption) {
                        myBalance = findConsumption.literVolume;
                      }
                      return (
                        <ConsumptionCard
                          type={productCode}
                          balance={myBalance}
                          key={key}
                          loading={fetchingFleetConsumption}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
            </>
          )}

          <ConfirmModal {...confirmModal} />
          <SuccessModal {...successModal} />
          {fleet.plbType === PLBTypes.PLB_LOCQPAY && (
            <>
              <div className={styles.locqpayBalanceContent}>
                <Image src={LOCQPayBalance} className={styles.locqpayBalanceImage} />
                <div className={styles.locqpayBalance}>
                  <span className={styles.locqpayBalanceAmount}>
                    {formatAmount(wallet?.walletBalance || 0)}
                  </span>
                  <span className={styles.locqpayBalanceText}>{locale.locqPayBalance}</span>
                </div>
              </div>
              {loyaltyError ? (
                <div className={styles.UnlinkVip}>
                  <Image src={UnLinkVIP} className={styles.vipUnLinkImage} />
                  <div className={styles.vipUnlinkContent}>
                    <ol className={styles.listofSteps}>
                      <li>
                        <span>{locale.linkVIPStep1}</span>
                      </li>
                      <li>
                        <span>{locale.linkVIPStep2}</span>
                      </li>
                    </ol>
                  </div>
                  <div className={styles.inputMobileNumber}>
                    <Loader open={fetchingLinkVIP} />
                    {!fetchingLinkVIP && (
                      <>
                        <Field className={styles.fieldLabel} {...fields.mobileNumber}>
                          <TextField
                            prefix={
                              <div className={styles.flagAndMobileInitial}>
                                <Image src={PhilippinesFlag} className={styles.flagPhilippines} />
                                <Text
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  +63{loyaltyError}
                                </Text>
                              </div>
                            }
                            {...fields.mobileNumber}
                            onBlur={validatedField}
                            onChange={modifyField}
                            disabled={disabledLinkVIPFields}
                          />
                        </Field>
                        <Button
                          primary
                          className={styles.linkAccountButton}
                          disabled={!isFormSubmittable || disabledLinkVIPFields}
                          onClick={() => {
                            submitForm(handleSubmit);
                          }}
                        >
                          {locale.linkAccount}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.linkVip}>
                  <Image src={LinkVIP} className={styles.vipLinkImage} />
                  <div className={styles.vipContent}>
                    <Loader open={loadingLoyaltyPoints} />
                    {!loadingLoyaltyPoints && (
                      <>
                        <div className={styles.vipDetails}>
                          <Text
                            style={{ fontSize: "19px", fontWeight: "bold" }}
                            strong
                          >{`${capitalize(fields?.firstName?.value)} ${capitalize(
                            fields?.lastName?.value
                          )}`}</Text>
                          <Text>{`+63${fields?.mobileNumber?.value}`}</Text>
                        </div>
                        <div className={styles.vipPoints}>
                          <Text
                            style={{ fontSize: "35px", fontWeight: "bold" }}
                          >{`${fields?.points?.value}`}</Text>
                          <Text style={{ fontWeight: "bold" }} label>
                            {"Points"}
                          </Text>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {showSurvey && !fetchingFeedbackRequest && (
        <div className={styles.surveyStar}>
          <div className={styles.header}>
            <CancelIcon
              className={styles.icon}
              onClick={() => {
                handleSkipOrClose();
                setStep2Data({});
                setStep2(false);
              }}
            />
          </div>
          {showStar && (
            <Text className={styles.headerTitleRating}>{locale.howsYourExperience}</Text>
          )}
          <div className={styles.surveyContent}>
            {!successSubmit && step2 && (
              <ReferPLBFriendForm initialState={{}} submitData={submitSurvey} />
            )}
            {!successSubmit && showStar && (
              <div className={styles.buttonStar}>
                {[...Array(5)].map((star, index) => {
                  index += 1;
                  return (
                    <button
                      key={index}
                      className={index <= rating ? styles.on : styles.off}
                      onClick={() => setRating(index)}
                      onMouseEnter={() => setHover(index)}
                      onMouseLeave={() => {
                        setHover(rating);
                      }}
                    >
                      {index <= rating ? (
                        <span className={styles.star}>&#9733;</span>
                      ) : (
                        <span className={styles.star}>&#9734;</span>
                      )}
                    </button>
                  );
                })}
              </div>
            )}

            {!successSubmit && satisfactory && showStar === false && step2 === false && (
              <SurveySatisfactoryForm
                setRating={setRating}
                setStep2={setStep2}
                setStep2Data={setStep2Data}
                initialState={setStep2Data}
                setShowSurvey={setShowSurvey}
                submitData={submitSurvey}
              />
            )}
            {!successSubmit && satisfactory === false && showStar === false && step2 === false && (
              <SurveyUnsatisfactoryForm
                setRating={setRating}
                setStep2={setStep2}
                setStep2Data={setStep2Data}
                initialState={setStep2Data}
                setShowSurvey={setShowSurvey}
                submitData={submitSurvey}
              />
            )}
            {!successSubmit && showStar && renderRating()}
            {!successSubmit && showStar && (
              <div className={styles.firstSkip}>
                <Button className={styles.skipFirst} onClick={handleSkipOrClose}>
                  {locale.skip}
                </Button>
              </div>
            )}

            {successSubmit && (
              <div className={styles.successFeedback}>
                <Text className={styles.headerTitleRating}>{locale.thankYouForYourFeedBack}</Text>
                <Text className={styles.bodyThankYou}>{locale.thankYouDescription}</Text>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardModule;
