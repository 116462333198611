import React, { useCallback, useMemo, useContext } from "react";
import locale from "localization";
import { Button, Field, Image, Radio, SelectCards, Text, TextField } from "components/commons";
import { CreditCards } from "images";
import styles from "./cashin-card.module.scss";
import { initialState as formState } from "./cashin-card.state";
import { useForm, useApi, useMount, useModal } from "hooks";
import { formatNumber, isNumeric, parseNumber } from "utils";
import { InputAmount } from "components/field";
import { createPaymentToken } from "apis";
import { createCashIn } from "apis/cash-in.api";
import { CashinCardPaymentSelection, CashInMethod, ErrorCode, Event } from "enums";
import { FleetContext } from "contexts";
import { redirectTo } from "utils";
import { handleRequest } from "utils";
import { useState } from "react";
import CashinCardRemoveCardModal from "./cashin-card-remove-card-modal";
import CashinCardAddCardModal from "./cashin-card-add-card-modal";
import CashinCardRemoveCardSuccessModal from "./cashin-card-remove-card-success-modal";

const CashinCard = ({ initialState, balance, fleetCards, disabled, fetchFleetCardsRequest }) => {
  const cashinCardRemoveCardModal = useModal();
  const cashinCardAddCardModal = useModal();
  const cashinCardRemoveCardSuccessModal = useModal();

  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const { fleet } = useContext(FleetContext);
  const { fleetId } = fleet || {};

  const [selectedCard, setSelectedCard] = useState(null);

  const {
    fields,
    modifyField,
    validateField,
    isFormSubmittable,
    submitForm,
    getFormValues,
    applyFieldErrors,
  } = useForm({
    initialState: form,
  });

  useMount(() => {
    setSelectedCard(fleetCards[fleetCards.length > 0 ? fleetCards.length - 1 : 0]);
  });

  const { request: createPaymentTokenRequest, loading: creatingPaymentToken } = useApi({
    api: createPaymentToken,
    handleOwnError: true,
  });

  const { request: createPaymayaCardCashInRequest, loading: creatingPaymayaCardCashInRequest } =
    useApi({
      api: createCashIn,
    });

  const onChangeExpiryDate = useCallback(
    (name, { value }) => {
      let v = value.toString();
      if (v.includes("/")) {
        v = v.replace("/", "");
      }
      if (isNumeric(v) && v.length <= 4) {
        modifyField(name, {
          value: `${v.substring(0, 2)}${v.length > 2 ? "/" : ""}${v.substring(2, 4)}`,
        });
      }
    },
    [modifyField]
  );

  // const feeAmount = useMemo(() => {
  //   const amount = parseNumber(fields.amount.value);
  //   let fee = amount * 0.007;
  //   return fee + 5;
  // }, [fields.amount]);

  const submit = async () => {
    const params = getFormValues();
    params.amount = parseNumber(params.amount);

    handleRequest(
      async () => {
        let paymentToken = null;

        if (selectedCard && selectedCard.cardToken) {
          paymentToken = selectedCard.cardToken;
        } else {
          const paymentTokenRes = await createPaymentTokenRequest({
            card: {
              number: params.cardNumber,
              expMonth: params.expiryDate.split("/")[0],
              expYear: `20${params.expiryDate.split("/")[1]}`,
              cvc: params.cvv,
            },
          });
          paymentToken = paymentTokenRes.paymentTokenId;
        }

        const paymentRes = await createPaymayaCardCashInRequest({
          amount: params.amount,
          method: CashInMethod.Card,
          fleetId,
          paymentToken: paymentToken,
        });

        redirectTo(paymentRes.verificationUrl);
      },
      null,
      (err) => {
        if (err.data?.code === "2553") {
          applyFieldErrors({
            cardNumber: locale.cardNumberYouEnterIsIncorrect,
          });
        } else if (err.data?.code === ErrorCode.CardNotSupported) {
          applyFieldErrors({
            cardNumber: locale.weAreOnlyAcceotingTheseCards,
          });
        } else {
          err.showError();
        }
      }
    );
  };

  const onSelectCard = (item) => {
    setSelectedCard(item);
  };

  const onDeleteCard = (item) => {
    cashinCardRemoveCardModal.show({
      fleetId: fleetId,
      cardType: item.cardType,
      fleetCardId: item.fleetCardId,
      cardNumber: item.cardNumber,
      onRemoveCardSuccess: onRemoveCardSuccess,
    });
  };

  const onRemoveCardSuccess = ({ cardType, cardNumber }) => {
    cashinCardRemoveCardModal.close();
    cashinCardRemoveCardSuccessModal.show({
      cardNumber,
      cardType,
      refetchFleetCardsRequest: fetchFleetCardsRequest,
    });
  };

  const onAddCard = () => {
    cashinCardAddCardModal.show({
      fleetId,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <Text label>{locale.weAreCurrentlyAccepting}</Text>
        <Image src={CreditCards} className={styles.creditCardImage} />
      </div>

      <div className={styles.step}>
        <Text header bolder>
          {locale.stepOneSelectCard}
        </Text>

        <Radio
          className={styles.radioContainer}
          radioClassName={styles.radio}
          value={fields.cashInCardPaymentSelection.value}
          options={[
            { text: locale.chooseFromMySavedCard, value: CashinCardPaymentSelection.SavedCards },
            { text: locale.useATemporaryCard, value: CashinCardPaymentSelection.TemporaryCard },
          ]}
          onChange={(name, { value }) => {
            modifyField("cashInCardPaymentSelection", {
              value: value,
            });

            setSelectedCard(value === CashinCardPaymentSelection.SavedCards ? fleetCards[0] : null);
          }}
          disabled={disabled}
        />
        {fields.cashInCardPaymentSelection.value === CashinCardPaymentSelection.SavedCards ? (
          <SelectCards
            className={styles.selectCards}
            fleetCards={fleetCards}
            onSelectCard={onSelectCard}
            onDeleteCard={onDeleteCard}
            selectedCard={selectedCard}
            onAddCard={onAddCard}
            disabled={disabled}
          />
        ) : (
          <>
            <Field {...fields.nameOnCard}>
              <TextField
                {...fields.nameOnCard}
                onChange={modifyField}
                onBlur={validateField}
                disabled={disabled}
              />
            </Field>
            <Field {...fields.cardNumber}>
              <TextField
                {...fields.cardNumber}
                onChange={(name, { value }) => {
                  const v = value.replaceAll(" ", "");
                  if (v.length <= 16) {
                    modifyField(name, { value: v });
                  }
                }}
                onBlur={validateField}
                disabled={disabled}
              />
            </Field>
            <div className={styles.cardInfor}>
              <Field {...fields.expiryDate} className={styles.half}>
                <TextField
                  {...fields.expiryDate}
                  onChange={onChangeExpiryDate}
                  onBlur={validateField}
                  disabled={disabled}
                />
              </Field>
              <Field {...fields.cvv} className={styles.half}>
                <TextField
                  {...fields.cvv}
                  onChange={modifyField}
                  onBlur={validateField}
                  disabled={disabled}
                />
              </Field>
            </div>
          </>
        )}
      </div>

      <div className={styles.step}>
        <Text header bolder>
          {locale.stepTwoEnterAmount}
        </Text>
        <div className={styles.amountSummary}>
          {[
            [locale.currentBalance, balance],
            [locale.minimumCashIn, 100],
            [locale.fee, 0],
          ].map((item, index) => {
            return (
              <div key={index}>
                <Text strong>{item[0]}</Text>
                <Text strong label>
                  ₱{formatNumber(item[1], 2)}
                </Text>
              </div>
            );
          })}
        </div>
        <Field {...fields.amount}>
          <InputAmount innerLabel onChange={modifyField} {...fields.amount} disabled={disabled} />
        </Field>
      </div>
      <Button
        loading={creatingPaymentToken || creatingPaymayaCardCashInRequest}
        className={styles.continue}
        primary
        disabled={
          fields.cashInCardPaymentSelection.value === CashinCardPaymentSelection.TemporaryCard
            ? !isFormSubmittable || disabled
            : fields.amount.value === "" || fields.amount.error || !selectedCard
        }
        onClick={() => {
          fields.cashInCardPaymentSelection.value === CashinCardPaymentSelection.TemporaryCard
            ? submitForm(submit)
            : submit();
        }}
        eventName={Event.CashInByCard}
      >
        {locale.continue}
      </Button>
      <CashinCardRemoveCardModal {...cashinCardRemoveCardModal} />
      <CashinCardAddCardModal {...cashinCardAddCardModal} />
      <CashinCardRemoveCardSuccessModal {...cashinCardRemoveCardSuccessModal} />
    </div>
  );
};

export default CashinCard;
