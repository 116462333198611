const ErrorCode = {
  IncorrectCredentials: "P1002",
  InactiveFleetStatus: "F1001",
  InvalidInviteFleetToken: "F1005",
  ExpiredInviteFleetToken: "F1003",
  InsufficientAmount: "W1001",
  CardNotSupported: "PY0036",
  BuyFuelLimitExceeded: "BF1003",
  UserNotFound: "P1005",
  UserInviteStillPending: "U1002",
  FleetNoLongerActive: "F1006",
  UserNotActive: "U1013",
  InvalidForgotPasswordToken: "E9999",
  InvalidPasswordToken: "P1009",
  ExpiredForgotPasswordToken: "P1010",
};

export default ErrorCode;
